import {Controller} from "stimulus"
import {post} from "@rails/request.js"
import Rails from "@rails/ujs";
export default class extends Controller {
    static values = {url: String}
    static targets = ['form', 'modalContainer', 'forBidding', 'floorPriceInput', 'priceInput']

    async openModal(event) {
        event.preventDefault()

        if(this.checkRequired()){
            let previousBtnText = event.target.value
            let disableBtnText = event.target.dataset.disableWith
            event.target.value = disableBtnText
            event.target.disabled = true

            let formData = new FormData(this.formTarget)
            const response = await post(`${this.urlValue}`, {
                body: formData,
                responseKind: "turbo-stream"
            })
            if (response.ok) {
                event.target.value = previousBtnText
                event.target.disabled = false
            }
        }
    }

    closeModal(event) {
        event.preventDefault()
        this.modalContainerTarget.classList.add('hidden')
    }

    submitForm(event) {
        event.preventDefault()
        Rails.fire(this.formTarget, 'submit');
    }

    updatePriceType(event) {
        event.preventDefault()
        console.log(event.target)
        const changeOnFloorPrice = event.target === this.floorPriceInputTarget
        this.forBiddingTarget.value = changeOnFloorPrice

    }

    checkRequired(){
        var pass = false
        const reqArr = document.querySelectorAll('.required')
        let iDiv = `
        <div id="requiredError" class="p-4 bg-red-100">
          <ul class="pl-5 mb-0 ">
              <li>This is a required field.</li>
          </ul>
        </div>`
        // reset all highlighted frames
        for(let i=0; i<reqArr.length; i++){
          document.querySelectorAll('.required')[i].parentElement.parentElement.classList.remove('border-2')
          document.querySelectorAll('.required')[i].parentElement.parentElement.classList.remove('border-red-500')
        }
        try {
          document.querySelectorAll('#requiredError').forEach(e => e.remove());
        } catch (error) {
          
        }
        // Check empty input and mark a red highlight for NFT (1)
        if(!document.querySelectorAll('.required')[0].parentElement.parentElement.querySelectorAll('input')[0].value){
          document.querySelectorAll('.required')[0].parentElement.parentElement.classList.add('border-2')
          document.querySelectorAll('.required')[0].parentElement.parentElement.classList.add('border-red-500')
          document.querySelectorAll('.required')[0].parentElement.parentElement.innerHTML += iDiv
          scroll(0, 0)
        } else{
            pass = true
        }
        // Check empty input and mark a red highlight for Fantastic/Moment/Rarity (2)
        for(let i=0; i<reqArr.length; i++){
          if(i<reqArr.length-2){
            if(i>0 && document.querySelectorAll('.required')[i].parentElement.parentElement.querySelectorAll('input:checked').length < 1){
              document.querySelectorAll('.required')[i].parentElement.parentElement.classList.add('border-2')
              document.querySelectorAll('.required')[i].parentElement.parentElement.classList.add('border-red-500')
              document.querySelectorAll('.required')[i].parentElement.parentElement.innerHTML += iDiv
              scroll(0, 0)
            } else{
                pass = pass && true
            }
          }
        }
        // Check empty input for Price or Floor price (3)
        if(reqArr[4].parentElement.parentElement.querySelector('input').value.replace('$', '').trim() > 0 || reqArr[5].parentElement.parentElement.querySelector('input').value.replace('$', '').trim() > 0 ){
            pass = pass && true
        } else{
          console.log('Price or Floor price must not be empty');
          scroll(0, 0)
          if(document.querySelectorAll('.required')[4].parentElement.parentElement.querySelector('input').value.replace('$', '').trim() <= 0){
            document.querySelectorAll('.required')[4].parentElement.parentElement.classList.add('border-2')
            document.querySelectorAll('.required')[4].parentElement.parentElement.classList.add('border-red-500')
            document.querySelectorAll('.required')[4].parentElement.parentElement.innerHTML += iDiv
          }
          if(document.querySelectorAll('.required')[5].parentElement.parentElement.querySelector('input').value.replace('$', '').trim() <= 0){
            document.querySelectorAll('.required')[5].parentElement.parentElement.classList.add('border-2')
            document.querySelectorAll('.required')[5].parentElement.parentElement.classList.add('border-red-500')
            document.querySelectorAll('.required')[5].parentElement.parentElement.innerHTML += iDiv
          }
        }
        return pass;
    }
}
