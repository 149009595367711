import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
    static targets = ['sportCombobox', 'countryComboBox', 'leagueComboBox', 'subLeagueComboBox', 'teamComboBox']
    
    connect() {
        this.preloadSelectedComboBoxes()
    }
    
    loadSportDependents() {
        this.queryLeagues()
        this.queryTeams()

        this.leagueComboBoxTarget.value = ''
        this.subLeagueComboBoxTarget.value = ''
        this.querySubLeagues()
    }
    
    loadCountryDependents() {
        this.queryLeagues()

        this.leagueComboBoxTarget.value = ''
        this.subLeagueComboBoxTarget.value = ''
        this.teamComboBoxTarget.value = ''
        this.querySubLeagues()
        this.queryTeams()
    }
    
    loadLeagueDependents() {
        this.querySubLeagues()
        this.queryTeams()
    }
    
    loadSubLeagueDependents() {
        this.queryTeams()
    }
    
    queryLeagues(selectedVal = null) {
        get('/nested_select_options/leagues', { 
            query: {
                'q[sport_id_eq]': this.sportComboboxTarget.selectedOptions[0].value,
                'q[country_id_eq]': this.countryComboBoxTarget.selectedOptions[0].value,
                'selected': selectedVal,
                'target': this.leagueComboBoxTarget.id,
            },
            responseKind: "turbo-stream" 
        })
    }
    
    querySubLeagues(selectedVal = null) {
        get('/nested_select_options/sub_leagues', { 
            query: {
                'q[sport_id_eq]': this.sportComboboxTarget.selectedOptions[0].value,
                'q[league_id_eq]': this.leagueComboBoxTarget.selectedOptions[0].value,
                'selected': selectedVal,
                'target': this.subLeagueComboBoxTarget.id,
            },
            responseKind: "turbo-stream" 
        })
    }
    
    queryTeams(selectedVal = null) {
        get('/nested_select_options/teams', { 
            query: {
                'q[sport_id_eq]': this.sportComboboxTarget.selectedOptions[0].value,
                'q[leagues_id_eq]': this.leagueComboBoxTarget.selectedOptions[0].value,
                'q[sub_leagues_id_eq]': this.subLeagueComboBoxTarget.selectedOptions[0].value,
                'selected': selectedVal,
                'target': this.teamComboBoxTarget.id,
            },
            responseKind: "turbo-stream" 
        })
    }
    
    async preloadSelectedComboBoxes() {
        this.queryLeagues(this.leagueComboBoxTarget.selectedOptions[0].value)
        setTimeout(() => {
            this.querySubLeagues(this.subLeagueComboBoxTarget.selectedOptions[0].value)
            setTimeout(() => {
                this.queryTeams(this.teamComboBoxTarget.selectedOptions[0].value)
            }, 1000)
        }, 1000)
    }
}
