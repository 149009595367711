import {Controller} from "stimulus"
import {get} from "@rails/request.js"

export default class extends Controller {
    static targets = ["price", "input", "output", "floorPrice", "normalPrice", "floorPriceInput", "normalPriceInput",
                        "forBidding", "priceInUsd"]

    connect() {
        // this.inputTarget.imask = IMask(this.inputTarget, this.imaskOptions);
        // console.log("updateETHTOUSD", this.updateEthToUsd())
        // console.log("input",this.inputTarget.value,"price",this.priceTarget.value)
        // if(this.inputTarget.value) {
            // console.log("value:",this.priceTarget.value)
            // this.outputTarget.innerHTML = "$ USD " + IMask.pipe(JSON.stringify(this.priceTarget.value), this.imaskOptions)
            // this.inputTarget.value = this.priceInUsdTarget.value;
            // console.log("updateETHTOUSD", this.updateEthToUsd())
            // this.updateEthToUsd();
        // }
    }

    clickRadio(e) {
        if (this.floorPriceTarget.className === 'bid activated') {
            this.priceTarget.name = 'picture[floor_price]'
            this.floorPriceInputTarget.name = 'picture[price]'
            this.forBiddingTarget.value = true
        } else {
            this.priceTarget.name = 'picture[price]'
            this.floorPriceInputTarget.name = 'picture[floor_price]'
            this.forBiddingTarget.value = false
        }
    }

    async updatePrice(e) {
        try {
            const getUSD = await this.checkUsd()
            const parseUSD = JSON.parse(getUSD)
            const {value} = e.target
            const formattedValue = IMask.pipe(value, this.imaskPipe);

            const checkNaN = formattedValue === '' ? 0 : formattedValue
            const lastPrice = parseFloat(checkNaN) / parseFloat(parseUSD.data.rates.ETH)


            this.priceTargets[0].innerHTML = lastPrice.toFixed(2)
            this.priceTargets.forEach((btn, index) => {
                this.priceTargets[index].value = lastPrice;
            });
            // console.log("lastPrice",lastPrice, IMask.pipe(JSON.stringify(lastPrice), this.imaskOptions))
            this.outputTarget.innerHTML = '$ USD ' + IMask.pipe(JSON.stringify(lastPrice), this.imaskOptions)
        } catch (e) {
            console.log(e)
        }
    }


    async checkUsd() {
        const response = await get('https://api.coinbase.com/v2/exchange-rates?' + 'USD=ETH',
            {headers: {
                'x-csrf-token': ''
            }}
        );
        if (response.ok) {
            const body = await response.text
            return body
        } else {
            const body = await response.text
            return body
        }
    }

    get imaskOptions() {
        return {
            mask: 'num',
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,  // digits after point, 0 for integers
                    min: 0,
                    max: 1000000000,
                    thousandsSeparator: ',',  // any single char
                    padFractionalZeros: true,  // if true, then pads zeros at end to the length of scale
                    radix: '.',  // fractional delimiter
                }
            }
        }
    }


    get imaskPipe() {
        return {
            mask: 'num',
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    thousandsSeparator: '',
                    normalizeZeros: true,
                    padFractionalZeros: true,
                    radix: '.',
                }
            }
        }
    }

    async updateEthToUsd() {
        try {
            const getUSD = await this.checkUsd()
            const parseUSD = JSON.parse(getUSD)

            const usdPrice = parseFloat(this.inputTarget.value) / parseFloat(parseUSD.data.rates.ETH)
            // console.log(this.inputTarget.value,parseUSD.data.rates),
            this.outputTarget.innerHTML = '$ USD ' +   IMask.pipe(JSON.stringify(usdPrice.toFixed(2)), this.imaskOptions)
        } catch(e) {
            console.log(e)
        }
    }
}
