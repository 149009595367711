import { Controller } from "stimulus";
import { ethers } from "ethers";
import { get, post, destroy } from "@rails/request.js";
import abiMinting from "../../../../abi/NFiniTi1155.json";
// import abiMinting from "../../../../abi/Marketplace1155.json";

export default class extends Controller {
  async connectMetamask() {
    await ethereum.request({ method: "eth_requestAccounts" });
  }

  async checkNetwork() {
    if (window.ethereum) {
      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });
      // 11155111 is Sepotia network id
      // 5 is Goerli network id
      // 80001 is Mumbai network id
      // 1 is mainnet eth network id
      return currentChainId == 1 ? true : false;
    }
  }

  async connect() {
    this.connectMetamask();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    this.signer = provider.getSigner();
    let contract_address = document.querySelector('#connected-group-contract');

    if(contract_address !== null) {
      this.contractAddress = contract_address.textContent;
    }
    (await this.checkNetwork())
      ? console.log("Connected")
      : console.log("Please change to Mumbai network");
    this.displayConnectedWallet();
  }

  async displayConnectedWallet() {
    // Show connected wallet address.
    let connectedWalletElement = document.querySelector('#connected-wallet');
    if (connectedWalletElement !== null) {
      this.userAddress = this.signer ? await this.signer.getAddress() : "Please connected wallet.";
      connectedWalletElement.textContent = this.userAddress;
    }

    // Show manager role status.
    let connectedManagerRoleElement = document.querySelector('#connected-manager-role');
    if (connectedManagerRoleElement !== null) {
      const mockupContractAddr = this.contractAddress;
      // const provider = new ethers.providers.JsonRpcProvider(
      //   "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
      // );
      const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-mumbai.infura.io/v3/3764e52848ab4036bc05bac644522d5c"
      );
      const readContract = new ethers.Contract(
        mockupContractAddr,
        abiMinting,
        provider
      );
      const managerRole = await readContract.MANAGER_ROLE();
      const HasManagerRole = await readContract.hasRole(managerRole, this.userAddress);
      connectedManagerRoleElement.textContent = HasManagerRole ? 'Yes' : 'No';
    }
  }

  async addGroup(e) {
    const spinnerBlock = document.querySelector('#addgroup_step1_spinner');
    e.target.disabled = true;
    spinnerBlock.style.display = "block";
    const groupId = document.querySelector('#addgroup_step1_group-id');
    const metadataURI = document.querySelector('#addgroup_step1_metadata-uri');
    const licenseURI = 'https://dev.nfiniti.art/license/';
    const maxAmountOfNFTs = document.querySelector('#addgroup_step1_max-amount-of-nfts');
    const maxEditionPerNFT = document.querySelector('#addgroup_step1_max-edition-per-nft');
    const royaltyFees = document.querySelector('#addgroup_step1_royalty-fees');
    const priceInWei = document.querySelector('#addgroup_step1_price-in-wei');
    const locked = false;

    const mintLater = document.querySelector("#mintable_false").checked;
    const nftDroppedAt = document.querySelector("#addgroup_step1_nft-dropped-at");
    const mintable = !mintLater;

    const mockupContractAddr = this.contractAddress;
    const provider = new ethers.providers.JsonRpcProvider(
      "https://polygon-mumbai.infura.io/v3/3764e52848ab4036bc05bac644522d5c"
    );

    const readContract = new ethers.Contract(
      mockupContractAddr,
      abiMinting,
      provider
    );

    const contract = new ethers.Contract(
      mockupContractAddr,
      abiMinting,
      this.signer
    );

    if (await readContract.groupExists(groupId.value)) {
      spinnerBlock.style.display = "none";
      e.target.disabled = false;
      return alert("This groupId has already been taken. Please change to other value.");
    }

    console.log("calling addGroup function in contract...");
    try {
      const tx = await contract.addGroup(
          groupId.value,
          metadataURI.value,
          licenseURI,
          maxAmountOfNFTs.textContent,
          maxEditionPerNFT.textContent,
          royaltyFees.textContent,
          priceInWei.textContent,
          mintable,
          locked,
      );
      const rc = await tx.wait();
      const event = rc.events.find((event) => event.event === "EvGroupAdded");
      let args = event.args;
      console.log(`groupId: ${args.groupId}`);
      console.log(`Transaction completed. Check this https://mumbai.polygonscan.io/tx/${tx.hash}`);
      await post(e.target.dataset.emitEventPath, {
        body: JSON.stringify({
          contract_group_id: groupId.value,
          tx: tx.hash,
          mint_later: mintLater,
          nft_dropped_at: nftDroppedAt.value,
          metadata_uri: metadataURI.value,
        }),
        responseKind: "turbo-stream"
      });
      Turbo.visit('');
    } catch (error) {
      console.error(error);
      spinnerBlock.style.display = "none";
      e.target.disabled = false;
    }
  }

  async updateGroupMetadataUri(e) {
    const spinnerBlock = document.querySelector('#addgroup_step3_spinner');
    e.target.disabled = true;
    spinnerBlock.style.display = "block";

    const metadataURI = document.querySelector('#updategroup_step3_metadatauri');
    const groupId = document.querySelector('#updategroup_step3_groupid');

    const mockupContractAddr = this.contractAddress;
    const contract = new ethers.Contract(
      mockupContractAddr,
      abiMinting,
      this.signer
    );

    console.log("calling updateGroupMetadataURI function in contract...");
    try {
      const tx = await contract.updateGroupMetadataURI(
        groupId.value,
        metadataURI.value,
      );
      const rc = await tx.wait();
      const event = rc.events.find((event) => event.event === "EvGroupMetadataURIUpdated");
      let args = event.args;
      console.log(`Transaction completed. Check this https://mumbai.polygonscan.io/tx/${tx.hash}`);
      await post(e.target.dataset.emitEventPath, {
        body: JSON.stringify({ uri: metadataURI.value, tx: tx.hash }),
        responseKind: "turbo-stream"
      });
      Turbo.visit('');
    } catch (error) {
      console.error(error);
      spinnerBlock.style.display = "none";
      e.target.disabled = false;
    }
  }

  async showLoadingIPFSUpload(e) {
    const spinnerBlock = document.querySelector('#addgroup_step2_spinner');
    e.target.disabled = true;
    spinnerBlock.style.display = "block";

    const response = await post(e.target.dataset.actionTarget, {
      responseKind: "turbo-stream"
    });
    if (response.ok) {
      spinnerBlock.style.display = "none";
      Turbo.visit('');
    }
  }

  async activateGroup(e) {
    const { gid, groupid, contractaddress } = e.target.dataset;
    groupid ?? alert("Group Id is empty");
    contractaddress ?? alert("Contract address is empty");
    const contract = new ethers.Contract(
      contractaddress,
      abiMinting,
      this.signer
    );
    try {
      const tx = await contract.activateGroup(groupid);
      const rc = await tx.wait();
      const options = {
        body: JSON.stringify({
          gid: gid
        }),
        responseKind: "turbo-stream",
      };
      alert("Activated");
      await post(`/contracts/update_drop/`, options);
      window.location.reload()
    } catch (error) {
      alert(error)
    }
  }

  async checkGroupAvailable(e) {
    const { nextGroupId } = e.target.dataset;

    e.target.disabled = true;

    const provider = new ethers.providers.JsonRpcProvider(
      "https://polygon-mumbai.infura.io/v3/3764e52848ab4036bc05bac644522d5c"
    );
    const readContract = new ethers.Contract(
      this.contractAddress,
      abiMinting,
      provider
    );

    if (await readContract.groupExists(nextGroupId)) {
      e.target.disabled = false;
      return alert("This groupId has already been taken. Please reload the page or contract admin.");
    } else {
      e.target.disabled = false;
      return alert(`Group ID ${nextGroupId} is available in smart-contract.`);
    }
  }

  toggleMintableRadio(e) {
    const mintLaterChecked = document.querySelector('#mintable_false').checked;
    const nftDropDateField = document.querySelector("#addgroup_step1_nft-dropped-at");
    nftDropDateField.disabled = !mintLaterChecked;
  }
}
