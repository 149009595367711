import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ["submit"]
    static values = {
        submitData: Object,
    }

    onRowClickHandler(e) {
        if (e.isTrusted) {
            const radio = e.currentTarget.querySelector('input[type="radio"]');
            radio.click();
            const {item, price, login, fm} = e.currentTarget.dataset
            this.submitDataValue = {price, item, login, fm}
        }
    }

    submitDataValueChanged() {
        this.submitTarget.disabled = !this.submitDataValue.item
        if (this.submitTarget.disabled) {

        } else {
            this.submitTarget.classList.remove('opacity-50')
        }


    }

    async onSubmitHandler() {
        await this.providerInstance.buyMarket(this.submitDataValue)
    }


    get providerInstance() {
        const web3ModalDom = document.querySelector('div.contract-select');
        return this.application.getControllerForElementAndIdentifier(web3ModalDom, "contract");
    }
}
