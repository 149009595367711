import {Controller} from "stimulus"
import {checkTerm} from "../hooks/user-init.hook";
import {post} from "@rails/request.js";

export default class extends Controller {

    async openModal(event) {
        const fm_id = this.element.dataset.id
        event.preventDefault()
        
        event.target.disabled = true
        
        const response = await post(`/marketplace/fantastic_moments/${fm_id}/fm_offers/make_offer`, {
            responseKind: "turbo-stream"
        })
        if (response.ok) {
            event.target.disabled = false
            document.getElementById('offerError').classList.add('hidden')
        }
    }
}