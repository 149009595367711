import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fileInput"]

  connect() {
    this.fileInputTarget.classList.add('hidden')
  }

  attach(event) {
    event.preventDefault()
    $(this.fileInputTarget).trigger('click')
  }
}
