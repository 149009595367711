import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["tagging", "tagName", "optionButton", "checkbox", "menu", "labelName", "selected", "tagsList", "optionsList", "filterBtn"];

    static classes = ["opitons"];

    connect() {

    }

    addremovetags(event) {
        const selected_option = event.target; // Get the selected option
        const btn_option = this.optionButtonTargets[selected_option.dataset.id]; // Get an option button     

        const option_list = this.menuTargets[selected_option.dataset.id].querySelectorAll('label'); // Get all checkbox in current menu
        const original_list = getAllOptions(option_list); // Get an original seq tags
        const tag_options = btn_option.dataset.value.split(','); // Get a current tags

        const checkbox = event.target.parentElement.querySelector('input.input-custom')

        // Get current state, not final state
        if (checkbox.checked) {
            // Remove Flow -> checked to unchecked
            tag_options.splice(tag_options.indexOf(selected_option.textContent), 1);
            btn_option.dataset.value = tag_options.join(',');
            if (btn_option.dataset.value === "") {
                if ((checkbox.attributes.type.value === "radio")) {
                    btn_option.textContent = "Choose " + btn_option.dataset.label;
                } else {
                    btn_option.textContent = "Choose " + btn_option.dataset.label;
                }
            } else {
                btn_option.textContent = btn_option.dataset.value
            }
        } else {
            // Default Flow -> unchecked to checked
            if (btn_option.dataset.value.split(',').length !== 0) {
                tag_options.push(selected_option.textContent);
                var filter_list = original_list.filter(option => tag_options.includes(option));

                if ((checkbox.attributes.type.value === "radio")) {
                    btn_option.dataset.value = selected_option.textContent
                } else {
                    btn_option.dataset.value = filter_list.join(',');
                }

                btn_option.textContent = btn_option.dataset.value;
            } else {
                btn_option.dataset.value = selected_option.textContent;
                btn_option.textContent = selected_option.textContent;
            }
        }
    }

    removetagsWithButton(event) {
        const selected_option = event.currentTarget.parentElement;
        const btn_option = this.optionButtonTargets[selected_option.dataset.catId]; // Get an option button        
        const tag_options = btn_option.dataset.value.split(','); // Get a current tags

        // Clear Text
        tag_options.splice(tag_options.indexOf(selected_option.textContent), 1);

        if (tag_options.length != 0) {
            btn_option.textContent = tag_options.join(',');
            btn_option.dataset.value = tag_options.join(',');
        } else {
            btn_option.dataset.value = ""
            btn_option.textContent = "Choose " + btn_option.dataset.label;
        }
        // remove tags and uncheck checkbox
        this.menuTargets[selected_option.dataset.catId].querySelectorAll("input.input-custom#" + selected_option.dataset.id)[0].checked = false
        selected_option.remove()

        if (this.tagsListTarget.children.length == 0) {
            this.tagsListTarget.parentElement.classList.add('d-none')
        }
    }

    addTags(event) {
        let current_tags = []
        const tagsList = this.tagsListTarget // get list container
            // this.tagsListTarget.innerHTML = "" // clear all 

        // get current tags in list
        if (tagsList.children.length > 0) {
            Array.from(tagsList.children).forEach((tag) => {
                current_tags.push(tag.dataset.id)
            })
        }

        const checked_checkbox = this.optionsListTarget.querySelectorAll(("input.input-custom:checked"))
            // add new tags, update current tags
        var add_tags = new Promise((resolve, reject) => {
            checked_checkbox.forEach(function(element) {
                if (!current_tags.includes(element.id)) {
                    // If doest not has tag in list. = New checked -> then create a tag
                    const data = element.parentElement.querySelector("label")
                    if (tagsList.querySelectorAll('[data-id="' + element.id + '"]').length < 1) {
                        tagsList.insertAdjacentHTML('beforeend', '<div data-auto-submit-form-target="dynamicField" class="filter-tagslist__item" data-cat-id="' + data.dataset.id + '" data-cat-name="' + data.dataset.optionName + '" data-id="' + element.id + '"><span>' + data.textContent + '</span><div class="filter-tagslist__remove" data-action="click->filter-tags#removetagsWithButton click->auto-submit-form#submitform"></div></div></div>');
                        resolve();
                    }
                } else {
                    // If has a tag. = still checked -> then remove in current_tags array.
                    current_tags.splice(current_tags.indexOf(element.id), 1);
                }
            })
        });

        // If current_tags still have value. = they are not in current checked -> then remove.
        if (current_tags.length > 0) {
            current_tags.forEach((elem) => {
                let tag = this.tagsListTarget.querySelector('[data-id="' + elem + '"]')
                if (tag != null) {
                    tag.remove()
                }
            })
        }

        if (checked_checkbox.length == 0) {
            this.tagsListTarget.parentElement.classList.add('d-none')
        } else {
            if (this.tagsListTarget.parentElement.classList.contains('d-none')) {
                this.tagsListTarget.parentElement.classList.remove('d-none')
            }
        }
    }

    clearAllTags() {
        this.tagsListTarget.parentElement.classList.add('d-none')
        this.tagsListTarget.innerHTML = ""
        this.optionButtonTargets.forEach(function(element) {
            element.textContent = "Choose " + element.dataset.label;
        })
        document.getElementById("search-form").querySelectorAll("input.input-custom:checked").forEach(function(checkbox) {
            checkbox.checked = false
        })
    }

    closeDropdown() {
        this.filterBtnTarget.classList.remove('show')
        this.optionsListTarget.classList.remove('show')
    }
}

function getAllOptions(list) {
    var filter_list = [];

    list.forEach(function(element) { filter_list.push(element.innerHTML); });

    return filter_list
}