import {Controller} from "stimulus"
// import select2 from "select2"
export default class extends Controller {
    static targets = ['radioNft', 'custom', 'fixed', 'defaultNft', 'licenseType', 'nftCount', 'radio']

    connect() {
        const default_selector = $('.btn-nft input[checked]').parent();

        default_selector.addClass('activated');

        if(default_selector.data('customizable') === true) {
            this.customTarget.disabled = false;
            this.customTarget.placeholder = default_selector.data('description');
            this.customTarget.classList.remove('d-none');
            this.fixedTarget.classList.add('d-none');
        } else {
            this.customTarget.disabled = true;
            this.customTarget.classList.add('d-none');
            this.fixedTarget.classList.remove('d-none');
            this.fixedTarget.innerHTML = undefined ? "" : default_selector.data('description');
        }

        this.selectLicenseType($(default_selector).data('license-type-id'));
    }

    async selectLicenseType(license_type_id) {
        var license_type_el = $('div.btn-license-type[data-id=' + license_type_id + ']');

        $('.btn-license-type.activated').toggleClass('activated');
        license_type_el.toggleClass('activated');
    }

    async selectNft(event) {
        const pointerId = event.pointerId;
        const id = event.target.value;
        const target_selector = $('.btn-nft[data-id=' + id + ']');
        const license_type_id = target_selector.data('license-type-id');
        const name = target_selector.data('name');
        const customizable = target_selector.data('customizable');
        const description = target_selector.data('description');

        if(pointerId < 0) {
            await this.addActive(id);
            await this.checkPlaceholder(name, customizable, description);
            await this.selectLicenseType(license_type_id)
        }
    }

    async descriptionCount() {
        this.nftCountTarget.innerHTML = this.customTarget.value.length
    }


    async radioChecked(event) {
        const {id} = event.target.dataset;
        await this.addActive(id);
        this.radioNftTargets[id].checked = true;
        const description = this.radioNftTargets[id].dataset.description;
        const name = this.radioNftTargets[id].dataset.name
        await this.checkPlaceholder(name,description)
    }


    async addActive(id){
        let current_target = 0;

        this.defaultNftTargets.forEach((el, i) => {
            if (this.defaultNftTargets[i].classList.value.search("activated") !== -1) {
                this.defaultNftTargets[i].classList.remove('activated')
            }

            if (this.defaultNftTargets[i].dataset.id === id) {
                current_target = i;
            }
        });
        this.defaultNftTargets[current_target].classList.add('activated');
    }

    async checkPlaceholder(name, customizable, description){
        let parser = new DOMParser();

        if (customizable === true) {
            this.customTarget.disabled = false
            this.customTarget.placeholder = description;
            this.customTarget.classList.remove('d-none');
            this.fixedTarget.classList.add('d-none');
        } else {
            this.customTarget.disabled = true
            this.customTarget.classList.add('d-none');
            this.fixedTarget.classList.remove('d-none');
            this.fixedTarget.innerHTML = undefined ? "" : description
        }
    }
}
