import {Controller} from "stimulus"
// import select2 from "select2"
export default class extends Controller {
    static targets = ['radioLicense', 'custom', 'fixed', 'defaultLicense', 'licenseCount', 'radio']

    connect() {
        const default_selector = $('.btn-license input[checked]').parent();

        default_selector.addClass('activated');
    }

    async selectLicense(event) {
        const pointerId = event.pointerId;
        const id = event.target.value;

        if(pointerId < 0) {
            await this.addActive(id)
        }
    }

    async descriptionCount() {
        this.licenseCountTarget.innerHTML = this.customTarget.value.length
    }


    async radioChecked(event) {
        const {id} = event.target.dataset
        await this.addActive(id)
        this.radioLicenseTargets[id].checked = true
        const description = this.radioLicenseTargets[id].dataset.description
        const name = this.radioLicenseTargets[id].dataset.name
        await this.checkPlaceholder(name,description)
    }


   async addActive(id){
       let current_target = 0;

        this.defaultLicenseTargets.forEach((el, i) => {
            if (this.defaultLicenseTargets[i].classList.value.search("activated") !== -1) {
                this.defaultLicenseTargets[i].classList.remove('activated')
            }

            if (this.defaultLicenseTargets[i].dataset.id === id) {
                current_target = i;
            }
        })
        this.defaultLicenseTargets[current_target].classList.add('activated');
    }

   async checkPlaceholder(name,description){
        if (name == 'Standard') {
            this.customTarget.disabled = false;
            this.customTarget.placeholder = "";
            this.customTarget.classList.remove('d-none');
            this.fixedTarget.classList.add('d-none');
        } else {
            this.customTarget.disabled = true;
            this.customTarget.classList.add('d-none');
            this.fixedTarget.classList.remove('d-none');
            this.fixedTarget.innerHTML = undefined ? "" :  description;
        }
    }
}
