import {Controller} from "stimulus"
import Uppy from "@uppy/core"
import DragDrop from '@uppy/drag-drop'
import ProgressBar from '@uppy/progress-bar'
import Tus from '@uppy/tus'

// import select2 from "select2"
export default class extends Controller {
    static targets = ['progressBar', 'dragDropZone', 'showNft', 'nftEdition', 'addNewForm', 'nestedFieldsImg', 'uploadInput', 'totalNfts', 'totalImages'];

    connect() {
        const uppy = new Uppy({
            id: 'nft_upload',
            autoProceed: true,
            restrictions: {
                allowedFileTypes: ['.jpg', '.jpeg', '.png']
            },
        }).use(DragDrop, {
            target: this.dragDropZoneTarget,
            height: '300px',
            width: '100%',
            locale: {
                strings: {
                    // Text to show on the droppable area.
                    // `%{browse}` is replaced with a link that opens the system file selection dialog.
                    dropHereOr: 'Drag & drop up to 30 images or \n\r' + '%{browse} to choose a file',
                    // Used as the label for the link that opens the system file selection dialog.
                    browse: 'Browse',
                },
            }
        }).use(Tus, {
            endpoint: 'https://tusd.tusdemo.net/files/'
        }).use(ProgressBar, {
            target: this.progressBarTarget,
            hideAfterFinish: true
        }).on('upload-success', (file, response) => {
            const url = response.uploadURL;
            const a = document.createElement('img');
            a.src = url;
            a.dataset.imgId = file.id;
            a.classList.add("img-fluid");
            a.classList.add("w-100");

            if(this.nestedFieldsImgTargets.length < 30) {
                this.addNewFormTarget.click();
                // const dataTransfer = new DataTransfer();
                // dataTransfer.items.add(file.data);
                // this.uploadImage[0].files = dataTransfer.files;
                this.uploadImage.parent().append(a);
                this.uploadImage.parent().find('[name*="tus_img_url"]').val(url);
                $(this.nftField).find('.change-img-btn').attr('data-id', $('.nft-form-nested').length);

                const uppy_btn = new Uppy({
                    id: 'nft_change',
                    autoProceed: true,
                    restrictions: {
                        allowedFileTypes: ['.jpg', '.jpeg', '.png']
                    },
                }).use(DragDrop, {
                    target: $(this.nftField).find('.change-img-btn')[0],
                    height: 'auto',
                    width: 'auto',
                    locale: {
                        strings: {
                            dropHereOr: 'Change',
                        }
                    },
                }).use(Tus, {
                    endpoint: 'https://tusd.tusdemo.net/files/'
                }).on('upload-success', (file, response) => {
                    const url = response.uploadURL;
                    const b = document.createElement('img');
                    b.src = url;
                    b.dataset.imgId = file.id;
                    b.classList.add("img-fluid");
                    b.classList.add("w-100");

                    uppy_btn.removeFile(this.currentActiveEl.find('img.img-fluid').data('img-id'));
                    uppy.removeFile(this.currentActiveEl.find('img.img-fluid').data('img-id'));

                    this.currentActiveEl.find('img.img-fluid').remove();

                    this.currentActiveEl.find('.nft-content').append(b);
                    this.currentActiveEl.find('[name*="tus_img_url"]').val(url);
                });
                this.setCharactersCounts(this.uploadImage.closest('.nft-form-nested').find('.characters_counts'));
            } else {
                uppy.removeFile(file.id);
                alert('You can upload up to maximum 30 images!');
            }
            this.updateTotalImagesNft();
            this.setLimitCharactersInput(this.nftField);
        });
        this.updateTotalImagesNft();
        this.addCocoonEvent();
        this.removeFileOnUppyEvent(uppy);
        this.setActiveNftBoxes(uppy);
        // this.resumeUploadFile(uppy);
    }

    //Private

    setCurrentTarget(el) {
        this.currentActiveEl = $(el.currentTarget).closest('.img-box');
    }

    setActiveNftBoxes(uppy) {
        $(this.nestedFieldsImgTargets).each(function () {
            // console.log($(this).parent().find('.change-img-btn')[0]);
            const uppy_btn = new Uppy({
                id: 'nft_change',
                autoProceed: true,
                restrictions: {
                    allowedFileTypes: ['.jpg', '.jpeg', '.png']
                },
            }).use(DragDrop, {
                target: $(this).parent().find('.change-img-btn')[0],
                height: 'auto',
                width: 'auto',
                locale: {
                    strings: {
                        dropHereOr: 'Change',
                    }
                },
            }).use(Tus, {
                endpoint: 'https://tusd.tusdemo.net/files/'
            }).on('upload-success', (file, response) => {
                const url = response.uploadURL;
                const b = document.createElement('img');
                b.src = url;
                b.dataset.imgId = file.id;
                b.classList.add("img-fluid");
                b.classList.add("w-100");
                var main_el = $(this).parent();

                uppy_btn.removeFile(main_el.find('img.img-fluid').data('img-id'));
                uppy.removeFile(main_el.find('img.img-fluid').data('img-id'));

                main_el.find('img.img-fluid').remove();

                main_el.find('.nft-content').append(b);
                main_el.find('[name*="tus_img_url"]').val(url);
            });
        });
    }

    addCocoonEvent() {
        $(this.element).on('cocoon:after-insert', (event, fields) => {
            this.uploadImage = fields.find('input[name*="tus_img_url"]');
            this.nftField = fields;
        });
    }

    removeFileOnUppyEvent(uppy) {
        $(this.element).on("cocoon:before-remove", (event, fields) => {
            const file_id = fields.find('img.img-fluid').data('img-id');

            uppy.removeFile(file_id);
        }).on("cocoon:after-remove", () => {
            this.updateTotalImagesNft();
        });
    }

    resumeUploadFile(uppy) {
        // When the form's submittng failed, Must re-upload to nft input.
        let tus_tk_elements = $('[name*="tus_img_tk"]');

        if(tus_tk_elements.length > 0) {
            tus_tk_elements.each(function(index, tus_element) {
                // const tus_id = $(tus_element).val();
                //
                // let tus_file = uppy.getFile(tus_id);
                // console.log(uppy.getFile('uppy-html/image/jpeg-2v-1e-image/jpeg-387665-1636045672991'));
                // console.log(uppy.getFiles());
                // console.log(tus_file);
                // console.log(index);
                // console.log(tus_element);
                // console.log(this);
            });
        }
    }

    updateTotalImagesNft() {
        var total_nft = 0;
        let image_edition_element = this.totalImagesTarget;
        let total_nfts_element = this.totalNftsTarget;
        let nft_edtion_elements = $(this.nftEditionTargets);

        if(this.nestedFieldsImgTargets.length > 0) {
            nft_edtion_elements.each(function () {
                total_nft = total_nft + parseInt(this.value);
            });
        }

        image_edition_element.innerText = nft_edtion_elements.length;
        total_nfts_element.innerText = total_nft;
    }

    setLimitCharactersInput(element) {
        var nft_edition_input = element.find('.nft-editions input[data-upload-nft-target*="nftEdition"]');

        $(nft_edition_input).on("input", function() {
            if ($(this).val().length > 10) {
                var total_number = $(this).val();

                $(this).val(total_number.slice(0, 10));
            }
        });
    }

    setCharactersCounts(current_element) {
        let el = current_element;
        var characterCount = el.find('.count-characters').val().length,
            current = el.find('#current');
        current.text(characterCount);

        current_element.find('textarea[count-characters], .count-characters').keyup(function() {
            var characterCount = $(this).val().length,
                current = el.find('#current'),
                maximum = el.find('#maximum'),
                theCount = el.find('.count-content');

            current.text(characterCount);
        });
    }
}
