//
//
import {post, get} from "@rails/request.js";
import {store} from "../store"
export async function useInit(options) {
    const response = await post(`/user/check_address`, options)
    if (response.ok) {
        const body = await response.text
        return body
    } else {
        const body = await response.text
        return body
    }


}

export async function checkAccount(user) {
    const response = await get(`/user/change_address?wallet_address=${user}`, {responseKind: 'turbo-stream'})
    if (response.ok) {
        const body = await response.text
        return body
    } else {
        const body = await response.text
        return body
    }

}


export async function checkUser(user) {
    const response = await get(`/user/check_user`, {responseKind: 'turbo-stream'})
    if (response.ok) {
        const body = await response.text
        return body
    } else {
        const body = await response.text
        return body
    }
}


export async function checkCurrentUser() {
    const response = await get(`/user/check_current`, {responseKind: 'turbo-stream'})
    if (response.ok) {
        const body = await response.text
        return body
    } else {
        const body = await response.text
        return body
    }

}


export async function checkTerm() {
    const response = await get(`/user/need_term_accept`, {responseKind: 'turbo-stream'})
    if (response.ok) {
        const body = await response.text
        return body
    } else {
        const body = await response.text
        return body
    }

}


export async function checkPasswordInit() {
    const response = await get(`/user/need_password_init`, {responseKind: 'turbo-stream'})
    if (response.ok) {
        const body = await response.text
        return body
    } else {
        const body = await response.text
        return body
    }
}
