import { Controller } from "stimulus";

export default class extends Controller {

    static targets = ["membership", "buyButton"];

    async connect() {
        // console.log('on buy button controller');
        // this.buyButtonState(this.buyButtonTargets);
    }

    buyButtonState(buyButtons) {
        // console.log('on buy buttons');
        // console.log(buyButtons);
        // console.log(this.membershipTarget);
        buyButtons.forEach(function(element) {
            const dropped_date = Date.parse(element.dataset.droppedAt);
            const timeset = Date.now();
            const difftime = (timeset - dropped_date)/3600/1000;
            // console.log(localStorage.getItem("membershipLevel"));
            const membership_level = parseInt(localStorage.getItem("membershipLevel"));
            let message = "";
            // console.log(Date.parse(element.dataset.droppedAt));
            // console.log(element.dataset);

            switch(membership_level) {
                case 3:
                    break;
                case 2:
                    if(difftime < 24) {
                        message = "Palladium Only";
                    }
                    break;
                case 1:
                    if(difftime < 24) {
                        message = "Palladium Only";
                    } else if (difftime < 48) {
                        message = "Mercury Only";
                    }
                    break;
                default:
                    console.log(difftime);
                    if(difftime < 24) {
                        message = "Palladium Only";
                    } else if (difftime < 48) {
                        message = "Mercury Only";
                    } else if (difftime < 72) {
                        message = "Ferric Only";
                    }
                    break;
            }

            if(message.length > 0) {
                element.innerHTML = message;
                element.href = "https://dev-members.nfiniti.art/";
                element.target = "_blank";
                element.dataset.bsToggle = 'tooltip';
            } else {
                element.title = '';
            }
        });

        // switch (element.dataset.droppedAt) {
        //     case x :
        //     default:
        // }
    }
}
