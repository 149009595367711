import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["normalPrice", "floorPrice", "editionSelect", "editionRadio", "charityBar", "charitySlider", "charityValue", "collectionField", "collectionSelectField", "fixed"]
    static values = {
        action: String
    }

    connect() {

        // this.priceBidAction(this.normalPriceTarget)
        // this.editionAction(this.editionSelectTarget);
        this.charityAction($(this.charitySliderTarget), $(this.charityBarTarget), $(this.charityValueTarget));
        
        // if (this.actionValue != 'edit') {
            // this.setCollectionSelect();
        // }
    }

    priceBidAction(normalPrice) {
        $(normalPrice).toggleClass('activated')
    }

    editionAction(edition) {
        let rarity_value;

        if(edition.querySelector("input[type='radio']:checked") === null){
            $(this.editionRadioTargets[0]).prop('checked', true);

            rarity_value = this.editionRadioTargets[0].value;
        } else {
            rarity_value = edition.querySelector("input[type='radio']:checked").value;
        }
        $(edition).find('.types[data-value="' + rarity_value + '"]').toggleClass('activated');

        $(edition).find('label.types').click(function () {
            $('.types.activated').toggleClass('activated');
            $(this).toggleClass('activated');
        });
    }

    charityAction(sliderbar, bar, value) {
        let valid_value;

        if(value.val() && value.val() >= 10) {
            valid_value = value.val();
        } else {
            valid_value = 0;
        }

        sliderbar.slider({
            create: function(event, ui) {
                if(value.val()) {
                    bar.text(value.val() + "%" );
                } else {

                    if($(this).slider("value") === 0 ) {
                        bar.text("0%");
                    } else {
                        bar.text($(this).slider("value") + "%" );
                    }
                }
            },
            slide: function( event, ui ) {
                let mod_value;
                if(ui.value === 0) {
                    mod_value = 0;
                } else {
                    mod_value = ui.value
                }
                bar.text( mod_value + "%" );
                value.val( mod_value );
            },
            step: 10,
            value: valid_value,
        });

        sliderbar.draggable();
    }

    // collectionSelect(event) {
    //     const new_selected = $(event.target).find(":selected").val();
    //     const new_description = $(event.target).find(":selected").data('descriptiopn');
    //
    //     this.toggleCollectionSelector(new_selected, new_description);
    // }

    // setCollectionSelect() {
    //     const new_selected = $(this.collectionSelectFieldTarget).find(":selected").val();
    //     const new_description = $(this.collectionSelectFieldTarget).find(":selected").data('descriptiopn');
    //
    //     this.toggleCollectionSelector(new_selected, new_description);
    // }

    // toggleCollectionSelector(new_selected, new_description) {
    //     const existed_selected = $(this.collectionSelectFieldTarget).find("[selected='selected']");
    //     const action_name = this.actionValue === "new" || this.actionValue === "create" ? 'new' : this.actionValue;
    //
    //     if ((new_selected !== 'new_collection') && (existed_selected.val() != new_selected || (action_name == "new"))) {
    //         this.collectionFieldTargets.forEach((elem) => {
    //             if($(elem).hasClass("collection-name")) {
    //                 elem.value = "";
    //                 elem.disabled = true;
    //             }
    //
    //             if($(elem).hasClass("collection-desciption")) {
    //                 $(elem).css("display", "none");
    //                 $(elem).val('');
    //             }
    //
    //             if($(elem).hasClass("fixed-field")) {
    //                 $(elem).css("display", "block");
    //                 $(elem).text(new_description || "");
    //             }
    //         });
    //
    //     } else {
    //         this.collectionFieldTargets.forEach((elem) => {
    //             elem.disabled = false;
    //             if($(elem).hasClass("collection-desciption")) {
    //                 $(elem).css("display", "block");
    //                 if (new_selected != 'new_collection') {
    //                     $(elem).val(existed_selected.data('descriptiopn'));
    //                 } else if ( new_selected == 'new_collection' && (action_name != "new")) {
    //                     $(elem).val("");
    //                 }
    //             }
    //
    //             if ($(elem).hasClass("collection-name")) {
    //                 if ((new_selected != 'new_collection')) {
    //                     elem.value = existed_selected.text();
    //                 } else if ( new_selected == 'new_collection' && (action_name != "new")) {
    //                     elem.value = "";
    //                 }
    //             }
    //
    //             if($(elem).hasClass("fixed-field")) {
    //                 $(elem).css("display", "none");
    //                 $(elem).text('');
    //             }
    //         });
    //     }
    // }
}
