import create from 'zustand/vanilla'


export const storeInitState = {
    currentProvider: null,
    currentContract: null,
    currentWeb3modal: null,
    currentLogin: '',
    currentUser: '',
    checkLogin: false,
    checkInit: false,
    currentSigner: null,
    currentAddress: '',
    currentSdk: null,
    clickLogout: true,
}

//
export const store = create((set, get) => ({
    ...storeInitState
}))



