$(document).on("turbo:load", function() {
    // let price = $(".price-or-bid > .price");
    // let bid = $(".price-or-bid > .bid");

    // if($("#price_or_bid_price").is(":checked")) {
    //     bid.toggleClass('activated');
    // } else {
    //     price.toggleClass('activated');
    // }
    // let rarity_types = $(".rarity-select");
    // let checked_rarity = rarity_types.find("input[type='radio']:checked").attr('id');

    // let license_types = $(".license_types_option");
    // let checked_license_type = license_types.find("input[type='radio']:checked").attr('id');

    // rarity_types.find('.types[for="' + checked_rarity + '"]').toggleClass('activated');
    // license_types.find('[for="' + checked_license_type +'"] .badge-bg-primary').toggleClass('activated');

    // $(".price-or-bid > .bid, .price-or-bid > .price").click(function () {
    //     var price_bid = $(this).val();
    //     if(price_bid == "price") {
    //         $(".price-or-bid > .price, .price-or-bid > .bid").toggleClass('activated');
    //     } else {
    //         $(".price-or-bid > .price, .price-or-bid > .bid").toggleClass('activated');
    //     }
    // });

    // rarity_types.find('label.types').click(function () {
        // console.log($('.types.activated')[0]);
        // console.log($(this)[0]);
    //     $('.types.activated').toggleClass('activated');
    //     $(this).toggleClass('activated');
    // });

    // license_types.find('label').click(function () {
    //     $('.badge-bg-primary.activated').toggleClass('activated');
    //     $(this).find('.badge-bg-primary').toggleClass('activated');
    // });

    // var handle = $( "#custom-handle" );
    // var charity = $("[type='hidden'].charity_custom");
    //
    // $( "#slider" ).slider({
    //     create: function(event, ui) {
    //         if(charity.val()) {
    //             handle.text(charity.val() + "%" );
    //         } else {
    //
    //             if($(this).slider("value") == 0 || $(this).slider("value") == 5) {
    //                 handle.text("5%");
    //             } else {
    //                 handle.text($(this).slider("value") + "%" );
    //             }
    //         }
    //     },
    //     slide: function( event, ui ) {
    //         let mod_value;
    //         if(ui.value == 0) {
    //             mod_value = 5;
    //         } else {
    //             mod_value = ui.value
    //         }
    //         handle.text( mod_value + "%" );
    //         charity.val( mod_value );
    //     },
    //     step: 10,
    //     value: charity.val() || 0,
    // });
});
