import {Controller} from "stimulus"
import Swiper from "swiper/swiper-bundle.min"

export default class extends Controller {

    static targets = ["mainBody", "currentPicture", "swiperCollection", "swiperMain", "swiperOther",
        "lightBox", "cLightBox", "cLightBoxContainer", "cLightBoxWrapper", "otherPreviousBtn", "otherNextBtn", "expandCustom"];

    connect() {
        if(this.element.dataset.selector === "mainNftSwiper") {
            this.swiperMainAndOther();
            this.swiperLightBox();
            this.switchInfo();
        } else if(this.element.dataset.selector === "collectionSwiper") {
            this.swiperCollection();
        }
    }

    setAllSwiper() {
        this.swiperMainAndOther();
        this.swiperLightBox();
        this.swiperCollection();
    }

    async swiperMainAndOther() {
        var current_img = this.setInitialSlide();
        var current_element = this;

        var swiper_other = new Swiper(this.swiperOtherTargets, {
            loop: false,
            spaceBetween: 1,
            setWrapperSize: true,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesProgress: true,
        });

        return new Swiper(this.swiperMainTargets, {
            loop: false,
            spaceBetween: 10,
            setWrapperSize: true,
            slidesPerView: 1,
            autoHeight:true,
            initialSlide: current_img,
            thumbs: {
                swiper: swiper_other,
            },
            navigation: {
                nextEl: this.otherPreviousBtnTarget,
                prevEl: this.otherNextBtnTarget,
            },
        }).on('slideChangeTransitionEnd', function(){
            current_element.switchInfo();
        });
    }

    switchInfo() {
        let img_seq = $('.mySwiper2 .swiper-slide-active').data('index');
        let nft_info = $('.nft-info');
        let nft_target = $('.nft-info[data-nft-seq="' + img_seq +'"]');

        nft_info.animate({opacity:'0'});
        nft_info.removeClass('active-box');

        nft_target.addClass('active-box');
        nft_target.animate({opacity:'1'});
    }

    swiperCollection() {
        return new Swiper(this.swiperCollectionTargets, {
            loop: false,
            spaceBetween: 1,
            setWrapperSize: true,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesProgress: true
        });
    }

    swiperLightBox() {
        let swiper_3;

        window.globals = {};

        const body = document.body;

        let lightboxController = this.application.getControllerForElementAndIdentifier(body, "lightbox");

        const lightboxImages = this.lightBoxTargets;

        const initLightbox = (lightboxImages.length > 0);

        const createLightboxSkeleton = () => {
            // Create skeleton for lightbox
            const [lightbox, lightboxContainer, lightboxClose, swiperContainer,
                swiperWrapper, swiperBtnNext , swiperBtnPrev, swiperPagination] = Array.from({length: 8}, e => document.createElement('div'));
            // Add classes
            lightbox.classList.add('c-lightbox');
            lightbox.dataset.lightboxTarget = 'cLightBox';
            lightboxContainer.classList.add('c-lightbox__container');
            lightboxContainer.dataset.swiperTarget = 'cLightBoxContainer';
            lightboxClose.classList.add('c-lightbox__close');
            lightboxClose.setAttribute('tabindex', '0');
            lightboxClose.innerHTML = 'X';
            swiperContainer.classList.add('swiper-container');
            swiperWrapper.classList.add('swiper-wrapper');
            swiperWrapper.dataset.lightboxTarget = "cLightBoxWrapper";
            swiperBtnNext.classList.add('swiper-button-next');
            swiperBtnPrev.classList.add('swiper-button-prev');
            swiperPagination.classList.add('swiper-pagination');

            // Append created divs [swiperWrapper, swiperBtnNext, swiperBtnPrev, swiperPagination] < swiperContainer < lightboxContainer < lightbox < body
            lightboxContainer.appendChild(lightboxClose);
            swiperContainer.appendChild(swiperWrapper);
            swiperContainer.appendChild(swiperBtnNext);
            swiperContainer.appendChild(swiperBtnPrev);
            swiperContainer.appendChild(swiperPagination);
            lightboxContainer.appendChild(swiperContainer);
            lightbox.appendChild(lightboxContainer);
            body.appendChild(lightbox);
            // Set variables to reference the lightbox
            globals.lightboxRef = lightboxController.cLightBoxTarget;
            globals.swiperWrapperRef = lightboxController.cLightBoxWrapperTarget;
        };

        if (initLightbox) {
            createLightboxSkeleton();
            // The rest of the code will go here
        }

        lightboxImages.forEach((el, index) => {
            // Add click function to lightbox images
            const element_target = this.expandCustomTarget;
            element_target.addEventListener('click', imageClick, false);

            function imageClick() {
                // Clear swiper before trying to add to it
                globals.swiperWrapperRef.innerHTML = '';
                // Loop through images with lightbox data attr
                // Create html for lightbox carousel
                lightboxImages.forEach((img) => {
                    // Create clone of current image in loop
                    const img_el = $(img).find('img')[0];
                    const image = img_el.cloneNode(true);
                    // Create divs
                    const slide = document.createElement('div');
                    const imageContainer = document.createElement('div');
                    // Add classes
                    slide.classList.add('swiper-slide');
                    imageContainer.classList.add('c-lightbox__image');
                    // Append images to the slides, then slides to swiper wrapper
                    imageContainer.appendChild(image);
                    slide.appendChild(imageContainer);

                    globals.swiperWrapperRef.appendChild(slide);
                });

                // Init Swiper
                swiper_3 = new Swiper(this.cLightBoxContainerTarget, {
                    initialSlide: index,
                    loop: true,
                    slidesPerView: 1,
                    speed: 750,
                    spaceBetween: 16,
                    watchOverflow: true,
                    setWrapperSize: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'fraction',
                    },
                    zoom: true,
                    fadeEffect: {
                        crossFade: false,
                    },
                    keyboard: {
                        enabled: true,
                        onlyInViewport: true,
                    },
                    mousewheel: {
                        sensitivity: 1,
                        forceToAxis: true,
                        invert: true,
                    },
                });
                // Add the class to open the lightbox
                // Add overflow hidden to the body to prevent scrolling
                globals.lightboxRef.classList.add('open');
                body.classList.add('overflowHidden');
            }
        });

        // Close lightbox on click
        document.addEventListener('click', ({ target }) => {
            if (target.matches('.c-lightbox__close')) {
                destroySwiper(swiper_3, 250);
                globals.lightboxRef.classList.remove('open');
                body.classList.remove('overflowHidden');
            }
        }, false);

        // Close lightbox on escape key press
        document.addEventListener('keydown', ({ key }) => {
            if (key === 'Escape') {
                destroySwiper(swiper_3, 250);
                globals.lightboxRef.classList.remove('open');
                body.classList.remove('overflowHidden');
            }
        });

        const destroySwiper = (swiper, timeout) => {
            setTimeout(() => {
                swiper.destroy();
            }, timeout);
        };

        // $('.nft-info.active-box').animate({opacity: '1'});
    }

    setInitialSlide() {
        // var img_id = this.currentPictureTarget.dataset.pictureId;
        var main_element = $('.main-swiper');
        var img_id = main_element.data("picture-id");

        return main_element.find('.swiper-slide[data-img-id="' + img_id + '"]').data('index');
    }

}
