import {ethers} from "ethers";
import Web3Modal from "web3modal";

// export const fantasticoAddress = FANTASTICO_ADDRESS
// export const marketplaceAddress = MARKETPLACE_ADDRESS

export const Singleton = (function () {
    var instance;
    var provider;


    function createProvider() {
        const providerOptions = {
            torus: {
                package: Torus, // required
                options: {
                    networkParams: {
                        host: NETWORK
                    },
                }
            },
            fortmatic: {
                package: Fortmatic,
                options: {
                    // Mikko's TESTNET api key
                    key: "pk_test_391E26A3B43A3350"
                }
            },
        }

        const web3Modal = new Web3Modal({
            network: NETWORK,
            theme: "light",// optional
            cacheProvider: false,
            disableInjectedProvider: false,
            providerOptions
        });

        return web3Modal;
    }




    return {
        getInstance: function () {
            // if (!instance) {
            instance = createProvider();
            // }
            // console.log(instance)
            return instance;
        },

        getProvider: function(){
          return this.provider;
        },

        setProvider: function(provider){
            this.provider = provider;
            return provider;
        },
    };
})();

export const LOADING = "LOADING"

// Exposed states of a Flow Transaction
export const IDLE = "IDLE"
export const PROCESSING = "PROCESSING"
export const SUCCESS = "SUCCESS"
export const ERROR = "ERROR"
export const TxURL = NETWORK === "mainnet" ? "https://etherscan.io/tx/" : "https://sepolia.etherscan.io/tx/"

// localStorage in Wallet

export async function clearLocalStorageAuthen(){
    const storagesToRemove = ["connect","ProviderConnect","chainChange","isProvider","login","isInit"]

    storagesToRemove.forEach((key) => {
        localStorage.removeItem(key);
    });
}

const chains = {
    mainnet: {
        id: 1
    },
    sepolia: {
        id: 11155111
    }
}

export const CHAIN_ID = chains[NETWORK].id

