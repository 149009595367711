import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "navbar"]

  connect() {
    if (!('localStorage' in window)) return;

    var lightMode = localStorage.getItem('gmtLightMode');
    if (lightMode) {
      this.intlLightMode();
      this.setDarkCheckboxes(false);
      this.element.classList.remove('dark');
    } else {
      this.removeIntlLightMode();
    }
  }

  toggle(event) {
    // Feature test
    if (!('localStorage' in window)) return;

    event.preventDefault();

    this.element.classList.toggle('dark');
    if (!this.element.classList.contains('dark')) {
      this.intlLightMode();
      this.setDarkCheckboxes(false);
      localStorage.setItem('gmtLightMode', true);
      return;
    }
    this.removeIntlLightMode();
    this.setDarkCheckboxes(true);

    localStorage.removeItem('gmtLightMode');
  }

  intlLightMode() {
    this.navbarTarget.classList.add('navbar-light');
  }

  removeIntlLightMode() {
    this.navbarTarget.classList.remove('navbar-light');
  }

  setDarkCheckboxes(checked) {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    })
  }
}
