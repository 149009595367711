import { Controller } from "stimulus";
import { ethers } from "ethers";
import { get, post, destroy } from "@rails/request.js";
// import abiMinting from "../../../abi/Marketplace1155.json";
import abiMinting from "../../../abi/NFiniTi1155.json";
import NFiniTiAbi from "../../../abi/NFiniTi-ERC721.json"
import { TxURL } from "../global/constant";
import Web3 from "web3";

export default class extends Controller {
  // static targets = ["input", "output", "cancel", "message", "error", "load", "sell", "buy", "mint", "submit", "price",
  //     "test", "confirm", "complete", "fail", "refresh", "marketplace", "owned", "showMarketplace", "greet", "ownedNft",
  //     "creatorCollection", "confirmMint", "approve"]
  // static values = {
  //     buyData: Object,
  //     sellData: Object,
  // }
  static targets = ["quantity"];

  // async connectMetamask() {
  //   await ethereum.request({ method: "eth_requestAccounts" });
  // }
  //
  // async checkNetwork() {
  //   if (window.ethereum) {
  //     const currentChainId = await window.ethereum.request({
  //       method: "eth_chainId",
  //     });
  //     // 5 is Goerli network id
  //     return currentChainId == 5 ? true : false;
  //   }
  // }
  async connect() {
    if($('#loadingModal').length > 0) {
      this.loading = new bootstrap.Modal("#loadingModal", {});
    }

    if($('#transactionModal').length > 0) {
      this.transaction = new bootstrap.Modal("#transactionModal", {});
    }

    this.currentUrl = new URL(window.location.href);

    /** Provider for reading contract */
    // const provider = new ethers.providers.JsonRpcProvider(
    //   "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"
    // );
    /** Provider for writing contract */
    // this.connectMetamask();
    // (await this.checkNetwork())
    //   ? console.log("Connected")
    //   : console.log("Please change to Goerli network");
    /*
        const providerOptions = {
            torus: {
              package: window.Torus, // required
              options: {
                networkParams: {
                  host: "https://rinkeby.infura.io/v3/b23867a5240a42a6bea17c2cadd4f944", // optional
                  chainId: 4, // optional
                  networkId: 4 // optional
                },
                config: {
                  buildEnv: "development" // optional
                }
              }
            }
          };
        console.log(providerOptions);
        const web3Modal = new Web3Modal({
            cacheProvider: false, // optional
            providerOptions, // required
            disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
        });
        try {
            provider = await web3Modal.connect();
        } catch(e) {
            console.log("Could not get a wallet connection", e);
            return;
        }
        */
    /// TODO: 1.Metamask
    // this.provider = new ethers.providers.Web3Provider(window.ethereum)
    // this.signer = this.provider.getSigner()
    // console.log('market!',this.marketplaceTarget);
    // console.log(this.hasCreatorCollectionTarget);
    // if (this.hasMarketplaceTarget) {
    // this.marketplaceFunction()
    // console.log('test');
    // }
    // if(this.hasCreatorCollectionTarget){
    //     this.showCreatorCollection()
    // }
    // const web3ModalDom = document.querySelector('body.authen-container');
    // const test = this.application.getControllerForElementAndIdentifier(web3ModalDom, "authenticate").oplogin;
  }

  //param status | true = completed, false = failed
  showTransactionModal(msg, status) {
    this.transaction.show();
    // reset icon
    document.querySelector(".status_success").classList.add("hidden");
    document.querySelector(".status_fail").classList.add("hidden");

    status
      ? document.querySelector(".status_success").classList.remove("hidden")
      : document.querySelector(".status_fail").classList.remove("hidden");
    document.querySelector(".status_title").textContent = status
      ? "Transaction completed"
      : "Transaction failed";
    document.querySelector(".status_msg").innerHTML = msg;
    document.querySelector(".status_btn").textContent = status
      ? "Continue"
      : "Try again";
  }

  async closeTransactionModal() {
    this.transaction.hide();
  }

  removePurchaseModal() {
    $("#confirmBuyModal").modal("hide");
  }

  async confirmBuy(e) {
    // const {
    //   price,
    //   tokenid,
    //   mockupcontractaddr,
    //   pictureid,
    //   nftname,
    //   remaining,
    //   collectionname,
    // } = e.target.dataset;
    // if (remaining == 1) {
    //   console.log(e.target);
    //   console.log(e.target.dataset.amount);
    //   e.target.dataset.amount = 1;
    //   this.buy(e);
    // } else {
    // new bootstrap.Modal(document.getElementById("confirmBuyModal")).show();
    // const modal = document.getElementById("confirmBuyModal");
    // modal.querySelector("#nftname").textContent = nftname;
    // modal.querySelector("#tokenId").textContent = tokenid;
    // modal.querySelector("#collectionName").textContent = collectionname;
    // modal.querySelector("#price").textContent = price;
    // modal.querySelector("#available").textContent = remaining;
    // modal.querySelector("#amount").max = remaining;
    // modal.querySelector("#buy").dataset.pictureid = pictureid;
    // modal.querySelector("#buy").dataset.price = price;
    // modal.querySelector("#buy").dataset.amount = 1;
    // modal.querySelector("#buy").dataset.tokenid = tokenid;
    // modal.querySelector(
    //   "#buy"
    // ).dataset.mockupcontractaddr = mockupcontractaddr;
    // $(modal.querySelector('crossmint-pay-button')).attr('mintConfig', '{"type":"erc-1155", "totalPrice": "' + price+ '", "tokenId_":"' + tokenid + '", "amount_":"1"}');
    // if($(modal.querySelector('crossmint-pay-button'))) {
    //   $(modal.querySelector("#amount")).on('change', function() {
    //     $(modal.querySelector('crossmint-pay-button')).attr('mintConfig', '{"type":"erc-1155", "totalPrice": "' + (price*$(this).val()) + '", "tokenId_":"' + tokenid + '", "amount_":"' + $(this).val() + '"}');
    //   }).trigger("change");
    // }
    // }
  }

  async buy(e) {
    /** Receive from query or any methods */
    if (localStorage.getItem("connect") == "true") {
      const {
        price,
        amount,
        contractaddress,
        pictureid,
      } = e.target.dataset;
      /** Connect to the contract */

      console.log('buy', e.target.dataset);

      const provider = this.providerInstance.provider;
      const signer = this.providerInstance.signer;
      const sender = this.providerInstance.address;
      // const totalPrice = price * amount;

      const priceInWei = BigInt(Web3.utils.toWei(price.toString(), "ether"));
      // const priceInWei = ethers.utils.parseUnits(totalPrice.toString(), 0)
      const overrides = {
        value: priceInWei,
        // gasLimit: 1e6
      };
      console.log('sender', sender, this.providerInstance);
      console.log('priceInWei', priceInWei);
      const contract = new ethers.Contract(
        contractaddress,
        NFiniTiAbi,
        signer
      );

      const { voucher } = await this.getAvailableTokens(pictureid, contract)

      if (voucher && amount > 0) {
        // console.log('vouchers', voucher.token_id)
        /** Close modal first */
        this.removePurchaseModal();
        /** Then show spinning */
        this.loading.show();
        try {
          console.log('mint', voucher.token_id, voucher.signature, overrides);

          /** Mint (make a transaction) */
          const tx = await contract.mint(voucher.token_id, voucher.signature, overrides);
          const rc = await tx.wait();
          const event = rc.events.find((event) => event.event === "Transfer");
          console.log('rc', rc,);
          console.log('events', rc.events);
          console.log('event', event);

          // let args = event.args;
          // args.id is tokenId
          // args.value is amount of minted NFT
          // args.operator or args.to is a transactor

          /** Add to sale history */
          // tx.hash // tx
          // tokenid //token_id
          // rc.to //creator_wallet
          // rc.from //buyer_wallet
          // weiToEther //price
          //////////////////////////

          /** Counter to total_minted for picture */
          // amount
          //////////////////////////////////////////

          const options = {
            body: JSON.stringify({
              pictureid: pictureid,
              amount: amount,
              token_id: voucher.token_id,
              creator_wallet: rc.to,
              buyer_wallet: rc.from,
              price: parseFloat(priceInWei),
              tx: tx.hash,
              ref: this.currentUrl.pathname // Buy from picture or creator page
            }),
            responseKind: "turbo-stream",
          };
          await post(`/contracts/mint/`, options);

          this.showTransactionModal(
            `View your transaction
            <a href="${TxURL}${tx.hash}" target="_blank">click</a>`,
            true
          );
        } catch (error) {
          // Update token back to available
          this.postCancelVoucher(pictureid, voucher.id)

          let err = error.error?.message ?? ""
          let finalMsg
          if (
            err.includes("insufficient funds") &&
            err.includes("have") &&
            err.includes("want")
          ) {
            finalMsg ="You don't have enough funds to do the transaction.";
          }
          this.removePurchaseModal();
          console.error(error);

          if (!finalMsg) { finalMsg = error.message.split("(")[0].split(";")[0]; }

          console.error(finalMsg.charAt(0).toUpperCase() + finalMsg.slice(1));
          // alert(error);
          this.showTransactionModal(
            finalMsg.charAt(0).toUpperCase() + finalMsg.slice(1),
            false
          );
        }
      } else {
        let errorMsg = "Unknown error";
        if (amount < 1) {
          errorMsg = "Invalid amount";
        }

        if (!voucher) {
          errorMsg = 'No available token'
        }

        // alert(errorMsg);
        this.removePurchaseModal();
        this.showTransactionModal(errorMsg, false);
        throw errorMsg;
      }
    } else {
      console.log("Open Wallet Modal");
      await get("/sync/wallet_modal", { responseKind: "turbo-stream" });
    }

    setTimeout(() => {
      this.loading.hide(); // Set delay to waiting for this.loading.show()
    }, 500)
  }

  get providerInstance() {
    const web3ModalDom = document.querySelector("body.authen-container");
    //   console.log(web3ModalDom);
    //   console.log(this.application.getControllerForElementAndIdentifier(
    //     web3ModalDom,
    //     "authenticate"
    // ));
    return this.application.getControllerForElementAndIdentifier(
        web3ModalDom,
        "authenticate"
    );
  }

  async getAvailableTokens(pictureid, contract) {
    const res = await get(`/contracts/get_voucher/${pictureid}`, {responseKind: "json"})
    const { vouchers } = await res.json
    let selectedVoucher, mintedVouchers = []

    for (const key in vouchers) {
      try {
        const address = await contract.ownerOf(parseInt(vouchers[key].token_id))
        mintedVouchers.push(key)
        // ownerOf[key] = { token_id: vouchers[key].token_id, address: address }
      } catch (error) {
        selectedVoucher = vouchers[key]
        selectedVoucher['id'] = key
        break;
      }
    }

    if (mintedVouchers) {
      await post(`/contracts/update_vouchers/`, {
        body: JSON.stringify({
          picture_id: pictureid,
          minted_voucher_ids: mintedVouchers,
          processing_voucher_id: selectedVoucher,
          ref: this.currentUrl.pathname
        }),
        responseKind: "json",
      });
    }
    // console.log('voucher', selectedVoucher);
    return { voucher: selectedVoucher }
  }

  postCancelVoucher(pictureId, voucherId) {
    post(`/contracts/cancel_voucher/`, {
      body: JSON.stringify({
        picture_id: pictureId,
        cancel_voucher_id: voucherId
      }),
      responseKind: "json",
    });
  }

  //Deprecated function
  // async update() {
  //   const pictureid = 19;
  //   const amount = 2;
  //   const tokenid = 1;
  //   const rc = {
  //     from: "1De",
  //     to: "Contract",
  //   };
  //   const weiToEther = "0.0000005";
  //   const tx = {
  //     hash: "0x0000120300",
  //   };
  //   const options = {
  //     body: JSON.stringify({
  //       pictureid: pictureid,
  //       amount: amount,
  //       token_id: parseInt(tokenid),
  //       creator_wallet: rc.to,
  //       buyer_wallet: rc.from,
  //       price: parseFloat(weiToEther),
  //       tx: tx.hash,
  //     }),
  //     responseKind: "turbo-stream",
  //   };
  //   await post(`/contracts/mint/`, options);
  // }

  // greet(e) {
  //     console.log('Greeting!');
  //     console.log(e);
  //     console.log(this.providerInstance.signer);
  // }

  // async checkApprove() {
  //     const contract = this.providerInstance.contract
  //     const sender = await contract.signer.getAddress() //msg.sender
  //     const status = await contract.isApprovedForAll(sender, marketplaceAddress)
  //     return status
  // }

  // adminApproval(){
  //     document.getElementById('overlayApproval').classList.remove('hidden')
  //     document.getElementById('btnAdminApproval').classList.remove('hidden')
  //     document.getElementById('msgApproval').innerHTML = 'As agreed we are going to create some Fantastic Moments for you and pay for the cost of doing so. To allow us to create NFTs on your behalf please press the approve button below. If you have any questions please <a class="cursor-pointer font-semibold underline" style="color: #3F3F3F !important;" onclick="openCrisp()">contact us</a>. Thank you.'
  // }

  // async confirmAdminApproval(e){
  //     let admin = e.target.dataset.adminwallet
  //     const contract = this.providerInstance.contract
  //     document.getElementById('loading').classList.remove('hidden')
  //     try {
  //         await contract.setApprovalForAll(admin, true)
  //         alert("Approved!");
  //     } catch (error) {
  //         alert(error)
  //     }
  //     document.getElementById('loading').classList.add('hidden')
  //     document.getElementById('overlayApproval').classList.add('hidden')
  //     Turbo.visit(``)
  // }

  // async revokeAdmin(e){
  //     let admin = e.target.dataset.adminwallet
  //     const contract = this.providerInstance.contract
  //     try {
  //         await contract.setApprovalForAll(admin, false)
  //         alert("Revoke!");
  //     } catch (error) {
  //         alert(error)
  //     }
  //     document.getElementById('loading').classList.add('hidden')
  //     document.getElementById('overlayApproval').classList.add('hidden')
  //     Turbo.visit(``)
  // }

  // async ApproveForAdmin(admin) {
  //     // document.getElementById('loading').classList.remove('hidden')
  //     // let admin = e.target.dataset.admin
  //     // console.log(e);
  //     const contract = this.providerInstance.contract
  //     const sender = await contract.signer.getAddress() //msg.sender
  //     if(admin.length == 42){
  //         const status = await contract.isApprovedForAll(sender, admin)
  //         console.log(status);
  //         // console.log(status)
  //         if (!status) {
  //             // document.getElementById('overlayApproval').classList.remove('hidden')
  //             this.adminApproval()
  //             // try {
  //             //     await contract.setApprovalForAll(admin, true)
  //             //     alert("Approved!");
  //             // } catch (error) {
  //             //     alert(error)
  //             // }
  //         }
  //         return status
  //     } else {
  //         alert("Wrong admin wallet length")
  //     }

  //     // document.getElementById('loading').classList.add('hidden')
  //     // document.getElementById('overlayApproval').classList.add('hidden')
  //     // Turbo.visit(``)
  // }

  // async disconnect() {

  // }

  // networkConnect() {
  //     return `The wallet network is incorrect. Please go to your wallet to set up the correct one. <a class="link-poly font-bold  md:underline" href="https://docs.polygon.technology/docs/develop/network-details/network" target="_blank" >Link</a>`
  // }

  // //mintByAthlete, mintByAdmin, sellFM
  // async isApprove() {
  //     const contract = this.providerInstance.contract
  //     const sender = await contract.signer.getAddress() //msg.sender
  //     const status = await contract.isApprovedForAll(sender, marketplaceAddress)
  //     console.log(marketplaceAddress);
  //     console.log(status)
  //     if (!status) {
  //         // document.getElementById('overlayApproval').classList.remove('hidden')
  //         await contract.setApprovalForAll(marketplaceAddress, true)
  //     }
  //     return status
  // }

  // async confirmApprove() {
  //     const contract = this.providerInstance.contract
  //     console.log(marketplaceAddress);
  //     console.log(contract);
  //     document.getElementById('loading').classList.remove('hidden')
  //     try {
  //         await contract.setApprovalForAll(marketplaceAddress, true)
  //         alert('Approved!');
  //     } catch (e) {
  //         alert(e)
  //     }
  //     document.getElementById('loading').classList.add('hidden')
  //     document.getElementById('overlayApproval').classList.add('hidden')
  //     Turbo.visit(``)
  // }

  // confirmMint(e){
  //     const {img, url, description, name, player, fantastic, moment, rarity, country, sport, playing, status, power, seed, price, athlete, amount, id, klass, isLogin} = this.confirmMintTarget.dataset
  //     const mintType = 'mint'
  //     document.getElementById('confirmMint').innerHTML = this.confirmMintModal(img, url, description, name, player, fantastic, moment, rarity, country, sport, playing, status, power, seed, price, athlete, amount, id, klass, isLogin, mintType)

  // }

  // confirmMintForAthlete(e){
  //     const {img, url, description, name, player, fantastic, moment, rarity, country, sport, playing, status, power, seed, price, athlete, amount, id, klass, isLogin} = this.confirmMintTarget.dataset
  //     console.log(name);
  //     const mintType = 'mintForAthlete'
  //     document.getElementById('confirmMint').innerHTML = this.confirmMintModal(img, url, description, name, player, fantastic, moment, rarity, country, sport, playing, status, power, seed, price, athlete, amount, id, klass, isLogin, mintType)

  // }

  // confirmMintModal(img, url, description, name, player, fantastic, moment, rarity, country, sport, playing, status, power, seed, price, athlete, amount, id, klass, isLogin, mintType) {
  //     console.log(name);
  //     console.log(description);
  //     console.log(klass);
  //     return `
  //     <div class=" flex fixed top-0 left-0 h-full w-full justify-center items-center" style="background: rgba(0,0,0,0.8); z-index: 998">
  //         <div class="bg-white p-5 rounded" data-controller="contract">
  //             <div x-data="{ charityFee: 10 }" class="w-80">
  //             <div class="font-bold text-l mb-3">
  //                 Confirm mint
  //             </div>
  //             <label for="charityFee" class="text-gray-700" x-text="'Charity fee ' + charityFee + '%'"></label>
  //             <input type="range" min="10" id="charityFee" max="100" x-model="charityFee" onchange="btnMint.dataset.charityFee = charityFee.value"
  //                 class="w-full h-2 bg-blue-100 appearance-none" />
  //             </div>
  //             <button id="btnMint" type="submit" class="text-center bg-gradient-to-tr from-pink-600 to-yellow-600 text-white font-bold py-2 px-4 rounded mt-3 float-left"
  //                                     data-action="click->contract#${mintType}"
  //                                     data-contract-target="mint"
  //                                     data-img="${img}"
  //                                     data-url="${url}"
  //                                     data-description="${description}"
  //                                     data-name="${name}"
  //                                     data-player="${player}"
  //                                     data-fantastic="${fantastic}"
  //                                     data-moment="${moment}"
  //                                     data-rarity="${rarity}"
  //                                     data-country="${country}"
  //                                     data-sport="${sport}"
  //                                     data-playing="${playing}"
  //                                     data-status="${status}"
  //                                     data-power="${power}"
  //                                     data-seed="${seed}"
  //                                     data-price="${price}"
  //                                     data-athlete="${athlete}"
  //                                     data-amount="${amount}"
  //                                     data-id="${id}"
  //                                     data-klass="${klass}"
  //                                     data-is-login="${isLogin}"
  //                                     data-charity-fee=10>Mint</button>
  //             <button class="text-center bg-gradient-to-tr from-pink-600 to-yellow-600 text-white font-bold py-2 px-4 rounded mt-3 float-right" onclick="confirmMint.innerHTML = ''">Cancel</button>
  //         </div>
  //     </div>`
  // }

  // async mint(e) {
  //     // if(await this.isApprove() == true){

  //     const {img, url, description, name, player, fantastic, moment, rarity, country, sport, playing, status, power, seed, price, athlete, amount, id, klass, admin, current, isLogin, charityfee} = this.mintTarget.dataset
  //     // console.log(admin)
  //     // console.log(await this.ApproveForAdmin(admin))
  //     // console.log(charityfee);
  //     // console.log(current)
  //     // document.getElementById('loading').classList.remove('hidden') //loading screen
  //     if (current === 'Administrator') {
  //         document.getElementById('overlayAdmin').classList.remove('hidden')
  //         document.getElementById('loading').classList.add('hidden')
  //     } else {
  //         if (await this.ApproveForAdmin(admin) == true) {
  //             try {
  //                 document.getElementById('loading').classList.remove('hidden')
  //                 /* The below code is converting an image to a hex string. */
  //                 //img to blob
  //                 const blob = await (await fetch(img)).blob();
  //                 console.log(blob);

  //                 //blob to base64
  //                 const blobToBase64 = blobData => {
  //                     const reader = new FileReader();
  //                     reader.readAsDataURL(blobData);
  //                     return new Promise(resolve => {
  //                         reader.onloadend = () => {
  //                             resolve(reader.result);
  //                         };
  //                     });
  //                 };

  //                 //to hex
  //                 const imgHash = blobToBase64(blob).then(res => {
  //                     // do what you wanna do
  //                     const data = res.split(`,`)[1]
  //                     const hexNum = this.hash(data).then((hex) => {
  //                         // console.log("0x"+hex);
  //                         return "0x" + hex;
  //                     });
  //                     return hexNum;
  //                 });
  //                 const fmImgHash = await imgHash;
  //                 // console.log(await imgHash);

  //                 /* END the converting algorithm */

  //                 /* NFT.Storage (BACKUP way)
  //                 const token = new URLSearchParams(window.location.search).get('key') || NFTSTORAGE_API_KEY // your API key from https://nft.storage/manage
  //                 const storage = new window.nftstorage({ token })
  //                 try {
  //                     const metadata = await storage.store({
  //                         name: 'test Name',
  //                         description:
  //                         'Using the nft.storage metadata API to create ERC-1155 compatible metadata.',
  //                         image: blob,
  //                     })
  //                     console.log({ 'IPFS URL for the metadata': metadata.url })
  //                     console.log({ 'metadata.json contents': metadata.data })
  //                     console.log({ 'metadata.json contents with IPFS gateway URLs': metadata.embed() })
  //                     console.log(metadata);
  //                 } catch (err) {
  //                     console.error(err)
  //                     console.log(err.message)
  //                 }
  //                 */

  //                 const imgUrl = await client.add(ipfs.urlSource(img))
  //                 const img_url = imgUrl.cid._baseCache.get('z')
  //                 console.log(img_url);
  //                 const data = JSON.stringify(
  //                     {
  //                         description: description,
  //                         external_url: url,
  //                         "image": "ipfs://" + img_url,
  //                         "name": name,
  //                         "attributes": [
  //                             {
  //                                 "trait_type": "Player",
  //                                 "value": player
  //                             },
  //                             {
  //                                 "trait_type": "Fantastic",
  //                                 "value": fantastic
  //                             },
  //                             {
  //                                 "trait_type": "Moment",
  //                                 "value": moment
  //                             },
  //                             {
  //                                 "trait_type": "Rarity",
  //                                 "value": rarity
  //                             },
  //                             {
  //                                 "trait_type": "Playing Country",
  //                                 "value": country
  //                             },
  //                             {
  //                                 "trait_type": "Sport",
  //                                 "value": sport
  //                             },
  //                             {
  //                                 "trait_type": "Playing",
  //                                 "value": playing
  //                             },
  //                             {
  //                                 "trait_type": "Status",
  //                                 "value": status
  //                             },
  //                             {
  //                                 "trait_type": "Class",
  //                                 "value": klass
  //                             },
  //                             {
  //                                 "trait_type": "Power",
  //                                 "max_value": 10,
  //                                 "value": (parseInt(power) > 0) ? parseInt(power) : 1
  //                             },
  //                             {
  //                                 "trait_type": "Seed",
  //                                 "max_value": 100,
  //                                 "value": (parseInt(seed) > 0) ? parseInt(seed) : 1
  //                             },
  //                             {
  //                                 "trait_type": "Share Charity",
  //                                 "max_value": 100,
  //                                 "value": parseInt(charityfee)
  //                             },
  //                         ],
  //                     })

  //                 console.log(data);
  //                 const data_url = await client.add(data)
  //                 // Time to mint
  //                 console.log(data_url);
  //                 console.log(data_url.path);
  //                 // const signer = new ethers.Wallet(`0x${this.providerInstance.oplogin.privKey}`, network);
  //                 /// TODO: Metamask
  //                 // let contract = new ethers.Contract(fantasticoAddress, Fantastico.abi, this.signer)
  //                 /// TODO: Torus
  //                 const provider = this.providerInstance.provider
  //                 const contract = new ethers.Contract(fantasticoAddress, Fantastico.abi, this.providerInstance.signer)
  //                 const fmCreated = contract.filters.FmCreated(provider.selectedAddress)
  //                 // console.log(fmCreated)

  //                 // console.log(parseInt(rarity));
  //                 // console.log(ethers.utils.parseEther(price));
  //                 const fmUrl = "ipfs://" + data_url.path
  //                 console.log('FM IMG:', fmImgHash)
  //                 console.log(typeof (fmUrl));
  //                 // let transaction = await contract.mintByAthlete(parseInt(a), parseInt(rarity), ethers.utils.parseEther(price), data_url.path)

  //                 const transaction = await contract.mintByAthlete(fmUrl, fmImgHash, amount, charityfee)

  //                 contract.on(fmCreated, async (fmCreatorAddress, tokenId, fmMaxSupply, fmURL) => {
  //                     console.log('FM Created')
  //                     const options = {
  //                         body: JSON.stringify({
  //                             tokenId: parseInt(tokenId),
  //                             fmImgHash: fmURL.toString(),
  //                             metadata: data,
  //                             id: id,
  //                             wallet: fmCreatorAddress,
  //                             amount: parseInt(fmMaxSupply),
  //                         }),
  //                         responseKind: 'turbo-stream'
  //                     }
  //                     await post(`/sync/mint/`, options)
  //                     alert('You have successfully minted your Fantastic MomentMinting Complete')
  //                     Turbo.visit(``)
  //                 })

  //             } catch (error) {
  //                 console.log(error)
  //                 document.getElementById('loading').classList.add('hidden') // Remove loading screen
  //                 let getError = error.message ? error.message : error.error.message
  //                 getError.includes("execution reverted: Image hash already exists") ? getError = "ERROR: Unfortunately we cant create a Fantastic Moment as the selected picture has already been used " : getError = getError
  //                 const forActive = "execution reverted: AccessControl: account " + this.providerInstance.address.toLowerCase() + " is missing role 0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6"
  //                 getError.includes(forActive) ? getError = "ERROR: You are not able to create the Fantastic Moments until your account has been activated. Please contact us to activate your account." : getError = getError
  //                 getError.includes("execution reverted: not between 10-100!") ? getError = "ERROR: The charity share is not between 10% and 100%" : getError = getError
  //                 getError.includes("execution reverted: amount > max amount") ? getError = "ERROR: The number minted exceeds the limits of the account" : getError = getError
  //                 getError.includes("Torus Tx Signature: User denied transaction signature.") ? getError = "ERROR: You have not approved the transaction" : getError = getError

  //                 this.errorTarget.classList.remove('hidden')
  //                 this.outputTarget.textContent = getError
  //                 // $("html, body").animate({scrollTop: 0}, 300);
  //             }
  //         } else {
  //             await this.ApproveForAdmin(admin)
  //         }
  //     }

  // }

  // async mintForAthlete(e) {
  //     e.preventDefault();
  //     // const signer = new ethers.Wallet(`0x${this.providerInstance.oplogin.privKey}`, network);
  //     // let contract = new ethers.Contract(fantasticoAddress, Fantastico.abi, signer)
  //     ////////
  //     console.log(e);
  //     // if(await this.isApprove() == true){
  //     // await this.isApprove()s
  //     const {img, url, description, name, player, fantastic, moment, rarity, country, sport, playing, status, power, seed, price, athlete, amount, id, klass, isLogin, charityfee} = this.mintTarget.dataset
  //         document.getElementById('loading').classList.remove('hidden') //loading screen
  //         try {

  //             // this.mintTargets.forEach((btn, index) => {
  //             //     this.mintTargets[index].innerHTML = this.spin()
  //             //     this.mintTargets[index].disabled = true
  //             // })
  //             /* The below code is converting an image to a hex string. */
  //             //img to blob
  //             const blob = await(await fetch(img)).blob();
  //             console.log(blob);

  //             //blob to base64
  //             const blobToBase64 = blobData => {
  //                 const reader = new FileReader();
  //                 reader.readAsDataURL(blobData);
  //                 return new Promise(resolve => {
  //                   reader.onloadend = () => {
  //                     resolve(reader.result);
  //                   };
  //                 });
  //               };

  //             //to hex
  //             const imgHash = blobToBase64(blob).then(res => {
  //                 // do what you wanna do
  //                 const data = res.split(`,`)[1]
  //                 const hexNum = this.hash(data).then((hex) => {
  //                     // console.log("0x"+hex);
  //                     return "0x"+hex;
  //                 });
  //                 return hexNum;
  //             });
  //             const fmImgHash = await imgHash;

  //             /* END the converting algorithm */
  //             const imgUrl = await client.add(ipfs.urlSource(img))
  //             const img_url = imgUrl.cid._baseCache.get('z')
  //             console.log(img_url);
  //             const data = JSON.stringify({
  //                 description: description,
  //                 external_url: url,
  //                 "image": "ipfs://"+img_url,
  //                 "name": name,
  //                 "attributes": [
  //                     {
  //                         "trait_type": "Player",
  //                         "value": player
  //                     },
  //                     {
  //                         "trait_type": "Fantastic",
  //                         "value": fantastic
  //                     },
  //                     {
  //                         "trait_type": "Moment",
  //                         "value": moment
  //                     },
  //                     {
  //                         "trait_type": "Rarity",
  //                         "value": rarity
  //                     },
  //                     {
  //                         "trait_type": "Playing Country",
  //                         "value": country
  //                     },
  //                     {
  //                         "trait_type": "Sport",
  //                         "value": sport
  //                     },
  //                     {
  //                         "trait_type": "Playing",
  //                         "value": playing
  //                     },
  //                     {
  //                         "trait_type": "Status",
  //                         "value": status
  //                     },
  //                     {
  //                         "trait_type": "Class",
  //                         "value": klass
  //                     },
  //                     {
  //                         "trait_type": "Power",
  //                         "max_value": 10,
  //                         "value": (parseInt(power) > 0) ? parseInt(power) : 1
  //                     },
  //                     {
  //                         "trait_type": "Seed",
  //                         "max_value": 100,
  //                         "value": (parseInt(seed) > 0) ? parseInt(seed) : 1
  //                     },
  //                     {
  //                         "trait_type": "Share Charity",
  //                         "max_value": 100,
  //                         "value": parseInt(charityfee)
  //                     },
  //                 ],
  //             })
  //             const data_url = await client.add(data)
  //             /*
  //             const token = new URLSearchParams(window.location.search).get('key') || NFTSTORAGE_API_KEY // your API key from https://nft.storage/manage
  //             const storage = new window.nftstorage({ token })
  //             console.log(storage);
  //             const tests = new File(['iVBORw0KGgoAAAANSUhEUgAAAM0AAADNCAMAAAAsYgRbAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABJQTFRF3NSmzMewPxIG//ncJEJsldTou1jHgAAAARBJREFUeNrs2EEKgCAQBVDLuv+V20dENbMY831wKz4Y/VHb/5RGQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ0PzMWtyaGhoaGhoaGhoaGhoaGhoxtb0QGhoaGhoaGhoaGhoaGhoaMbRLEvv50VTQ9OTQ5OpyZ01GpM2g0bfmDQaL7S+ofFC6xv3ZpxJiywakzbvd9r3RWPS9I2+MWk0+kbf0Hih9Y17U0nTHibrDDQ0NDQ0NDQ0NDQ0NDQ0NTXbRSL/AK72o6GhoaGhoRlL8951vwsNDQ0NDQ1NDc0WyHtDTEhDQ0NDQ0NTS5MdGhoaGhoaGhoaGhoaGhoaGhoaGhoaGposzSHAAErMwwQ2HwRQAAAAAElFTkSuQmCC'],
  //             'beastie.png', { type: 'image/png' })
  //             console.log(tests);
  //             try {
  //                 const metadata = await storage.store({
  //                     name: 'test Name',
  //                     description:
  //                     'Using the nft.storage metadata API to create ERC-1155 compatible metadata.',
  //                     image: tests,
  //                 })
  //                 // log({ 'IPFS URL for the metadata': metadata.url })
  //                 // log({ 'metadata.json contents': metadata.data })
  //                 // log({ 'metadata.json contents with IPFS gateway URLs': metadata.embed() })
  //                 console.log(metadata);
  //             } catch (err) {
  //                 console.error(err)
  //                 log(err.message)
  //             }
  //             */
  //             const fmUrl = "ipfs://" + data_url.path
  //             /// TODO: Metamask
  //             // let contract = new ethers.Contract(fantasticoAddress, Fantastico.abi, this.signer)
  //             /// TODO: Torus
  //             const provider = this.providerInstance.provider
  //             const contract = new ethers.Contract(fantasticoAddress, Fantastico.abi, this.providerInstance.signer)
  //             const fmCreated = contract.filters.FmCreated(provider.selectedAddress)
  //             // console.log(charityfee);
  //             // console.log(fmCreated)
  //             let transaction = await contract.mintForAthlete(athlete.toString(), fmUrl, fmImgHash, amount, charityfee)
  //             // const rc = await transaction.wait()
  //             // console.log(rc)
  //             contract.on(fmCreated, async (fmCreatorAddress, tokenId, fmMaxSupply, fmURL) => {
  //                 console.log('FM Created')
  //                 const options = {
  //                     body: JSON.stringify({
  //                         tokenId: parseInt(tokenId),
  //                         fmImgHash: fmURL.toString(),
  //                         metadata: data,
  //                         id: id,
  //                         wallet: fmCreatorAddress,
  //                         amount: parseInt(fmMaxSupply),
  //                     }),
  //                     responseKind: 'turbo-stream'
  //                 }
  //                 await post(`/sync/mint/`, options)
  //                 alert('You have successfully minted your Fantastic MomentMinting Complete')
  //                 Turbo.visit(``)
  //             })
  //         } catch (error) {
  //             console.log(error);
  //             console.log(this.errorTargets)
  //             document.getElementById('loading').classList.add('hidden') // remove loading screen
  //             let getError = error.message ? error.message : error.error.message
  //             getError.includes("execution reverted: creator address need MINTER_ROLE!") ? getError = "ERROR: This athlete does not yet have the Minter Role" : getError = getError
  //             getError.includes("execution reverted: Image hash already exists") ? getError = "ERROR: Unfortunately we cant create a Fantastic Moment as the selected picture has already been used " : getError = getError
  //             getError.includes("execution reverted: not between 10-100!") ? getError = "ERROR: The charity share is not between 10% and 100%" : getError = getError
  //             getError.includes("execution reverted: amount > max amount") ? getError = "ERROR: The number minted exceeds the limits of the account" : getError = getError
  //             getError.includes("Torus Tx Signature: User denied transaction signature.") ? getError = "ERROR: You have not approved the transaction" : getError = getError
  //             this.errorTarget.classList.remove('hidden')
  //             this.outputTarget.textContent = getError
  //             // $("html, body").animate({scrollTop: 0}, 300);
  //         }
  //     // }
  // }

  // //Credit: https://remarkablemark.medium.com/how-to-generate-a-sha-256-hash-with-javascript-d3b2696382fd
  // async hash(string) {
  //     const utf8 = new TextEncoder().encode(string);
  //     const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  //     const hashArray = Array.from(new Uint8Array(hashBuffer));
  //     const hashHex = hashArray
  //       .map((bytes) => bytes.toString(16).padStart(2, '0'))
  //       .join('');
  //     return hashHex;
  // }

  // async autoGrantMinterRole(e){
  //     const address_ = e.view.document.getElementById('athlete_user_attributes_wallet_address').value
  //     console.log(address_);
  //     const contract = this.providerInstance.contract
  //     let transaction = await contract.grantRole(MINTER_ROLE, address_)
  //     console.log(transaction);
  //     alert('Grant completed')
  // }

  // async grantMinterRole(e) {
  //     const address_ = e.target.dataset.walletaddress
  //     console.log(address_)
  //     const contract = this.providerInstance.contract
  //     console.log(contract);
  //     document.getElementById('loading').classList.remove('hidden') //loading screen
  //     try {
  //         let transaction = await contract.grantRole(MINTER_ROLE, address_)
  //         console.log(transaction);
  //         alert('Grant completed')
  //         Turbo.visit(``)
  //     } catch (error) {
  //         alert(error)
  //         document.getElementById('loading').classList.add('hidden') // remove loading screen
  //     }
  //     // this.outputTarget.textContent = transaction

  // }

  // async revokeMinterRole(e) {
  //     const address_ = e.target.dataset.walletaddress
  //     console.log(address_);
  //     const contract = this.providerInstance.contract
  //     document.getElementById('loading').classList.remove('hidden') //loading screen
  //     let transaction = await contract.revokeRole(MINTER_ROLE, address_)
  //     console.log(transaction);
  //     alert('Revoke completed')
  //     Turbo.visit(``)
  // }

  // async buyFM(obj) {
  //     const {marketid, price} = obj.currentTarget.dataset
  //     console.log(marketid);
  //     console.log(price);
  //     document.getElementById('loading').classList.remove('hidden') //loading screen
  //     let overrides = {
  //         value: ethers.utils.parseEther(price)
  //     };

  //     // const signer = new ethers.Wallet(`0x${this.providerInstance.oplogin.privKey}`, network);
  //     // let contract = new ethers.Contract(marketplaceAddress, Marketplace.abi, signer)
  //     const market = this.providerInstance.market

  //     try {
  //         let transaction = await market.buyFM(marketid, 1, overrides)
  //         console.log(transaction.hash);
  //         let receipt = await transaction.wait()
  //         let event = receipt.events.find(event => event.event === "CompletedSale");
  //         let args = event.args;
  //         // console.log(args)
  //         console.log("tokenId: " + args.tokenId);
  //         console.log("charityAmount: " + parseFloat(args.charityAmount.toString()) / 10 ** 18);
  //         console.log("creatorWallet: " + args.creatorWallet);
  //         console.log("buyer: " + args.buyer);
  //         const options = {
  //             body: JSON.stringify({
  //                 tokenId: parseInt(args.tokenId),
  //                 charityAmount: parseFloat(args.charityAmount.toString()) / 10 ** 18,
  //                 creatorWallet: args.creatorWallet,
  //                 buyerWallet: args.buyer,
  //                 tx: transaction.hash
  //             }),
  //             responseKind: 'turbo-stream'
  //         }
  //         await post(`/sync/buy/`, options)
  //         Turbo.visit(``)
  //     } catch (error) {
  //         alert(error)
  //         document.getElementById('loading').classList.add('hidden') // remove loading screen
  //     }
  // }

  // async createAsk(e) {
  //     e.preventDefault();
  //     const reserved = "0x0000000000000000000000000000000000000000"
  //     let tokenId = document.getElementById('owned').dataset.tokenid
  //     let price = ethers.utils.parseEther(document.getElementById('price').value.toFixed(5))
  //     let amount = document.getElementById('amount').value
  //     let fmid = e.target.dataset.fmid
  //     let userid = e.target.dataset.userid
  //     // console.log(price);
  //     console.log(amount);
  //     console.log(tokenId);
  //     console.log(price);
  //     if (await this.isApprove() == true) {
  //         try {
  //             document.getElementById('loading').classList.remove('hidden') //loading screen
  //             const market = this.providerInstance.marketplace
  //             const transaction = await market.createAsk([tokenId], [price], [amount], [reserved])
  //             console.log(transaction.hash);
  //             let receipt = await transaction.wait()
  //             console.log(receipt);
  //             let event = receipt.events.find(event => event.event === "CreateAsk");
  //             console.log(event);
  //             let args = event.args;
  //             console.log(args)

  //             const options = {
  //                 body: JSON.stringify({
  //                     tokenId: parseInt(args.tokenId),
  //                     unitPrice: parseFloat(ethers.utils.formatEther(args.unitPrice)),
  //                     amount: parseInt(args.amount),
  //                     transactor_address: receipt.from,
  //                     fmid: fmid,
  //                     userid: userid,
  //                     tx: transaction.hash
  //                 }),
  //                 responseKind: 'turbo-stream'
  //             }
  //             console.log(options);
  //             await post(`/sync/create_ask/`, options)

  //             Turbo.visit(``)
  //         } catch (error) {
  //             console.log(error);
  //             // document.getElementById('errorToast').classList.remove('hidden')
  //             // document.getElementById('errorToastMsg').textContent = e
  //             document.getElementById('loading').classList.add('hidden')
  //             document.getElementById('overlayApproval').classList.add('hidden')
  //             let getError = error.error.message ? error.error.message : error
  //             getError.includes("Not owner of token Id") ? getError = "Sorry, we can’t proceed your request because you have set the FM amounts more than what you own." : getError=getError
  //             document.getElementById('error').classList.remove('hidden')
  //             document.getElementById('output').textContent = getError
  //             document.getElementById('overlaySell').classList.add('hidden')
  //             scroll(0,0)
  //         }
  //     }
  // }

  // async sellFM(e) {
  //     const contract = this.providerInstance.contract
  //     const address = this.providerInstance.address
  //     // this.isApprove()
  //     const reserved = "0x0000000000000000000000000000000000000000"
  //     let tokenId = e.target.dataset.tokenid
  //     let price = ethers.utils.parseEther(e.target.dataset.price)
  //     let amount = contract.balanceOf(address, tokenId)
  //     let userid = e.target.dataset.userid
  //     let fmid = e.target.dataset.fmid
  //     if (await this.isApprove() == true) {
  //         try {
  //             document.getElementById('loading').classList.remove('hidden') //loading screen
  //             // const price = ethers.utils.parseEther(document.querySelector("#price").value)
  //             // const amount = parseInt(document.querySelector("#amount").value)
  //             // const tokenId = document.getElementById('owned').dataset.tokenid
  //             console.log(tokenId);
  //             console.log(price);
  //             console.log(amount);

  //             const market = this.providerInstance.marketplace
  //             const transaction = await market.createAsk([tokenId], [price], [amount], [reserved])
  //             console.log(transaction.hash);
  //             let receipt = await transaction.wait()
  //             console.log(receipt);
  //             let event = receipt.events.find(event => event.event === "CreateAsk");
  //             console.log(event);
  //             let args = event.args;
  //             console.log(args)

  //             const options = {
  //                 body: JSON.stringify({
  //                     tokenId: parseInt(args.tokenId),
  //                     unitPrice: parseFloat(e.target.dataset.price),
  //                     amount: parseInt(args.amount),
  //                     transactor_address: receipt.from,
  //                     fmid: fmid,
  //                     userid: userid,
  //                     tx: transaction.hash
  //                 }),
  //                 responseKind: 'turbo-stream'
  //             }
  //             console.log(options);
  //             await post(`/sync/create_ask/`, options)

  //             Turbo.visit(``)
  //         } catch (e) {
  //             console.log(e);
  //             // document.getElementById('errorToast').classList.remove('hidden')
  //             // document.getElementById('errorToastMsg').textContent = e
  //             document.getElementById('loading').classList.add('hidden')
  //             document.getElementById('overlayApproval').classList.add('hidden')
  //         }
  //     }

  // }

  // async sellModal(e) {
  //     let tokenId = e.target.dataset.tokenid
  //     document.getElementById('owned').dataset.tokenid = tokenId
  //     document.getElementById('overlaySell').classList.remove('hidden')
  // }

  // async cancelAsk(e) {
  //     let tokenId = e.target.dataset.tokenid
  //     const market = this.providerInstance.marketplace
  //     console.log(tokenId);
  //     try {
  //         document.getElementById('loading').classList.remove('hidden') //loading screen
  //         const tx = await market.cancelAsk([tokenId]);
  //         const receipt = await tx.wait();
  //         let event = receipt.events.find(event => event.event === "CancelAsk");
  //         console.log(event);
  //         let args = event.args;
  //         console.log(args);
  //         const options = {
  //             body: JSON.stringify({
  //                 tokenId: parseInt(args.tokenId),
  //                 transactor_address: receipt.from,
  //                 tx: tx.hash
  //             }),
  //             responseKind: 'turbo-stream'
  //         }
  //         await post(`/sync/cancel_ask/`, options)
  //         alert('Cancel completed')
  //         Turbo.visit(``)

  //     } catch (error) {
  //         alert(error)
  //         document.getElementById('loading').classList.add('hidden') // remove loading screen
  //     }
  //     // const marketArray = await market.fetchMyUnsoldItems(tokenId)
  //     // console.log(marketArray);
  //     // if (marketArray.length > 0) {
  //     //     document.getElementById('loading').classList.remove('hidden') //loading screen
  //     //     marketArray.forEach(item => {
  //     //         arr.push(item.marketId.toString())
  //     //     })
  //     //     for (let i = 0; i < arr.length; i++) {
  //     //         try {
  //     //             const tx = await market.withdrawFM(arr[i]);
  //     //             const receipt = await tx.wait();
  //     //             let event = receipt.events.find(event => event.event === "MarketplaceAction");
  //     //             let args = event.args;
  //     //             const options = {
  //     //                 body: JSON.stringify({
  //     //                     tokenId: parseInt(args.tokenId),
  //     //                     wallet: address,
  //     //                 }),
  //     //                 responseKind: 'turbo-stream'
  //     //             }
  //     //             await destroy(`/sync/cancel_offer/`, options)

  //     //         } catch (error) {
  //     //             alert(error)
  //     //             document.getElementById('loading').classList.add('hidden') // remove loading screen
  //     //         }
  //     //     }
  //     //     alert('Cancel completed')
  //     //     Turbo.visit(``)
  //     // } else {
  //     // }
  // }

  // async acceptAsk(e) {
  //     const market = this.providerInstance.marketplace
  //     let tokenId = e.target.dataset.tokenid
  //     let seller = e.target.dataset.seller
  //     let buyer = this.providerInstance.address
  //     let unitPrice = e.target.dataset.unitprice
  //     let amount = e.target.dataset.amount
  //     let totalPrice = unitPrice*amount
  //     let overrides = {
  //         value: ethers.utils.parseEther(totalPrice.toString())
  //     };
  //     try {
  //         document.getElementById('loading').classList.remove('hidden') //loading screen
  //         const tx = await market.acceptAsk([tokenId], [amount], [seller], overrides);
  //         const receipt = await tx.wait();
  //         let event = receipt.events.find(event => event.event === "AcceptAsk");
  //         let hasBid = receipt.events.find(event => event.event === "CancelBid");
  //         console.log(event);
  //         let args = event.args;
  //         console.log(args);
  //         const options = {
  //             body: JSON.stringify({
  //                 tokenId: parseInt(args.tokenId),
  //                 transactor_address: receipt.from,
  //                 seller: seller,
  //                 buyer: receipt.from,
  //                 unitPrice: unitPrice,
  //                 tx: tx.hash,
  //                 amount: parseInt(args.amount),
  //             }),
  //             responseKind: 'turbo-stream'
  //         }
  //         await post(`/sync/accept_ask/`, options)
  //         if(!!hasBid){
  //             await post(`/sync/cancel_bid/`, options)
  //         }
  //         alert('Buy succeeded')
  //         Turbo.visit(``)

  //     } catch (error) {
  //         console.log(error);
  //         document.getElementById('loading').classList.add('hidden') // remove loading screen
  //         let getError = error.error.message ? error.error.message : error
  //         getError.includes("Marketplace::cant accept own ask") ? getError = "Sorry, we can’t proceed your request as you cannot buy your own FM." : getError=getError
  //         getError.includes("Marketplace::Seller has not enough NFT") ? getError = "You cannot fill more FM amounts than what are sold in the market right now." : getError=getError
  //         document.getElementById('error').classList.remove('hidden')
  //         document.getElementById('output').textContent = getError
  //         document.getElementById('overlayBuy').classList.add('hidden')
  //         scroll(0,0)
  //     }
  // }

  // async confirmAsk(e) {
  //     document.getElementById('overlayBuy').classList.remove('hidden')
  //     document.getElementById('submit').dataset.tokenid = e.target.dataset.tokenid
  //     document.getElementById('submit').dataset.seller = e.target.dataset.seller
  //     document.getElementById('submit').dataset.unitprice = e.target.dataset.unitprice
  // }

  // async createBid(e) {
  //     const reserved = "0x0000000000000000000000000000000000000000"
  //     let tokenId = document.getElementById('owned').dataset.tokenid
  //     let unitPrice = document.getElementById('bidPrice').value.toFixed(5)
  //     let unitPriceETH = ethers.utils.parseEther(unitPrice.toString())
  //     let amount = document.getElementById('bidAmount').value
  //     let fmid = document.getElementById('owned').dataset.fmid
  //     let userid = e.target.dataset.userid
  //     let totalPrice = unitPrice*amount
  //     let overrides = {
  //         value: ethers.utils.parseEther(totalPrice.toString())
  //     };
  //     console.log(unitPrice);
  //     console.log(amount);
  //     console.log(fmid);
  //     console.log(userid);
  //     try {
  //         document.getElementById('loading').classList.remove('hidden') //loading screen
  //         const market = this.providerInstance.marketplace
  //         const transaction = await market.createBid([tokenId], [unitPriceETH], [amount], [reserved], overrides)
  //         console.log(transaction)
  //         console.log(transaction.hash);
  //         let receipt = await transaction.wait()
  //         console.log(receipt);
  //         let event = receipt.events.find(event => event.event === "CreateBid");
  //         console.log(event);
  //         let args = event.args;
  //         console.log(args)

  //         const options = {
  //             body: JSON.stringify({
  //                 tokenId: parseInt(args.tokenId),
  //                 unitPrice: parseFloat(ethers.utils.formatEther(args.unitPrice)),
  //                 amount: parseInt(args.amount),
  //                 transactor_address: receipt.from,
  //                 fmid: fmid,
  //                 userid: userid,
  //                 tx: transaction.hash,
  //                 offer_type: 'Make Offer',
  //             }),
  //             responseKind: 'turbo-stream'
  //         }
  //         console.log(options);
  //         await post(`/sync/create_bid/`, options)

  //         Turbo.visit(``)
  //     } catch (error) {
  //         console.log(error);
  //         // document.getElementById('errorToast').classList.remove('hidden')
  //         // document.getElementById('errorToastMsg').textContent = e
  //         document.getElementById('loading').classList.add('hidden')
  //         document.getElementById('overlayApproval').classList.add('hidden')
  //         let getError = error.error.message ? error.error.message : error
  //         getError.includes("price must be > 0") ? getError = "Please fill the Offer price and amount that you’d like to offer." : getError=getError
  //         getError.includes("amount must be > 0") ? getError = "Please fill the Offer price and amount that you’d like to offer." : getError=getError
  //         document.getElementById('offerError').classList.remove('hidden')
  //         document.getElementById('offerOutput').textContent = getError
  //         scroll(0,0)
  //     }
  // }

  // async cancelBid(e) {
  //     const reserved = "0x0000000000000000000000000000000000000000"
  //     let tokenId = document.getElementById('owned').dataset.tokenid
  //     const market = this.providerInstance.marketplace
  //     console.log(tokenId);
  //     try {
  //         document.getElementById('loading').classList.remove('hidden') //loading screen
  //         const tx = await market.cancelBid([tokenId], [reserved]);
  //         const receipt = await tx.wait();
  //         let event = receipt.events.find(event => event.event === "CancelBid");
  //         console.log(event);
  //         let args = event.args;
  //         console.log(args);
  //         const options = {
  //             body: JSON.stringify({
  //                 tokenId: parseInt(args.tokenId),
  //                 transactor_address: receipt.from,
  //                 tx: tx.hash,
  //                 offer_type: 'Cancel Bid',
  //             }),
  //             responseKind: 'turbo-stream'
  //         }
  //         await post(`/sync/cancel_bid/`, options)
  //         alert('Cancel completed')
  //         Turbo.visit(``)

  //     } catch (error) {
  //         alert(error)
  //         document.getElementById('loading').classList.add('hidden') // remove loading screen
  //     }
  // }

  // async confirmBid(e) {
  //     document.getElementById('overlaySell').classList.remove('hidden')
  //     document.getElementById('buyer').textContent = e.target.dataset.buyer
  //     document.getElementById('unitprice').textContent = e.target.dataset.unitprice
  //     document.getElementById('sConfirm').dataset.unitprice = e.target.dataset.unitprice
  //     document.getElementById('sConfirm').dataset.tokenid = e.target.dataset.tokenid
  //     document.getElementById('sConfirm').dataset.buyer = e.target.dataset.buyer
  //     document.getElementById('sConfirm').dataset.seller = e.target.dataset.seller
  // }

  // async acceptBid(e) {
  //     // await this.isApprove()
  //     const market = this.providerInstance.marketplace
  //     const signerAddress = this.providerInstance.address
  //     console.log(market);
  //     let tokenId = e.target.dataset.tokenid
  //     let seller = e.target.dataset.seller
  //     let buyer = e.target.dataset.buyer
  //     let amount = e.target.dataset.amount
  //     console.log(tokenId);
  //     console.log(amount);
  //     console.log(buyer);
  //     console.log(seller);
  //     try {
  //         document.getElementById('loading').classList.remove('hidden') //loading screen
  //         const tx = await market.acceptBid([tokenId], [amount], [buyer], [seller]);
  //         const receipt = await tx.wait();
  //         let event = receipt.events.find(event => event.event === "AcceptBid");
  //         let hasAsk = receipt.events.find(event => event.event === "CancelAsk");
  //         console.log(receipt)
  //         console.log(!!hasAsk)
  //         console.log(event);
  //         let args = event.args;
  //         console.log(args);
  //         const options = {
  //             body: JSON.stringify({
  //                 tokenId: parseInt(args.tokenId),
  //                 transactor_address: receipt.from,
  //                 seller: seller,
  //                 buyer: buyer,
  //                 unitPrice: parseFloat(ethers.utils.formatEther(args.unitPrice)),
  //                 tx: tx.hash,
  //                 amount: parseInt(args.amount),
  //                 offer_type: 'Accept Offer'
  //             }),
  //             responseKind: 'turbo-stream'
  //         }
  //         await post(`/sync/accept_bid/`, options)
  //         if(!!hasAsk){
  //             console.log('DeleteAsk')
  //             await post(`/sync/cancel_ask/`, options) //Delete ask
  //         }

  //         alert('Sell succeeded')
  //         Turbo.visit(``)

  //     } catch (error) {
  //         console.log(error);
  //         document.getElementById('loading').classList.add('hidden') // remove loading screen
  //         let getError = error.error.message ? error.error.message : error
  //         getError.includes("Marketplace::cant accept own ask") ? getError = "Sorry, we can’t proceed your request as you cannot buy your own FM." : getError=getError
  //         getError.includes("Marketplace::Seller has not enough NFT") ? getError = "You can’t sell more FM than what has been offered." : getError=getError
  //         getError.includes("execution reverted") && getError.length == 18 ? getError = "You can’t sell more FM than what has been offered." : getError=getError
  //         document.getElementById('error').classList.remove('hidden')
  //         document.getElementById('output').textContent = getError
  //         document.getElementById('overlaySell').classList.add('hidden')
  //         scroll(0,0)
  //     }
  // }

  // async withdrawTin() {
  //     const market = this.providerInstance.marketplace
  //     document.getElementById('loading').classList.remove('hidden') //loading screen
  //     try {
  //         await market.withdraw()
  //         alert('withdraw succeeded')
  //         Turbo.visit('')
  //     } catch (e) {
  //         console.log(e);
  //         document.getElementById('loading').classList.add('hidden') // remove loading screen
  //     }
  // }

  // async tinBalance() {
  //     const market = this.providerInstance.marketplace
  //     console.log(market);
  //     const signerAddress = this.providerInstance.address
  //     const tinBal = await market.escrow(signerAddress)
  //     const bal = ethers.utils.formatUnits(tinBal, 'ether') //convert to Ether
  //     const inner = this.withdrawTinModal(bal)
  //     document.getElementById('overlayWithdraw').innerHTML = inner
  // }

  // withdrawTinModal(bal) {
  //     return `
  //     <div id="overlayWithdraw" data-controller="contract" class=" flex fixed top-0 left-0 h-full w-full justify-center items-center" style="background: rgba(0,0,0,0.8); z-index: 998">
  //         <div>
  //         <ul class="bg-white p-5 my-3 rounded-lg" data-contract-target="sell">
  //             <li>
  //             <p>
  //                 Balance: ${bal}, do you want to withdraw?
  //             </p>
  //             </li>
  //             <li class="font-bold flex justify-between mt-3">
  //             <div class="text-green-600 cursor-pointer	" data-action="click->contract#withdrawTin">Withdraw</div>
  //             <button class="text-red-600" onclick="document.getElementById('overlayWithdraw').innerHTML = ''">Cancel</button>
  //             </li>
  //         </ul>
  //         </div>
  //     </div>`
  // }

  // loading() {
  //     return `<svg class="inline animate-spin h-6 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  //                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#FFF" stroke-width="4"></circle>
  //                 <path class="opacity-75" fill="#FFF" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  //             </svg>`
  // }

  // async closeAlert() {
  //     this.errorTarget.classList.add('hidden')
  // }

  // spin() {
  //     return `
  //         <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-4 w-4 mr-2 ml-2 mt-1 "></div>
  //   `
  // }

  // spinCancel() {
  //     return `
  //         <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-4 w-4   mr-5 ml-5 mt-1 "></div>
  //   `
  // }

  // async test() {
  //     await Turbo.visit('')

  // }

  // async finished() {
  //     await Turbo.visit('')
  // }

  // modalSpin() {
  //     return ` <svg class="inline animate-spin -ml-1 mr-1 h-10 w-12" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  //                   <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
  //                   <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  //                 </svg>
  //                 Pending
  //               `
  // }
}
