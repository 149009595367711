import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
      console.log('hello');
        // var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        // var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        //   return new bootstrap.Tooltip(tooltipTriggerEl)
        // });
    }

  // test(){
  //   console.log("ImageController")
  // }
}
