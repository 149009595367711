import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['uploadInput', 'uploadPreviewer']

    preview(event) {
        this.uploadPreviewerTarget.src = window.URL.createObjectURL(this.uploadInputTarget.files[0])
        this.uploadPreviewerTarget.classList.remove('hidden')
    }


}
