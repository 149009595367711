import { Controller } from "stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {

  changeFMs(e){
    let target = $('#FMs')[0].id
    console.log(target);
    var collection = e.target
    console.log(collection);
    if(collection.id == "myCollection"){
      collection = ''
    } else{
      collection = collection.textContent
    }
    let creator = document.URL.split('/').pop()
    console.log(creator);
    get(`/creators/fms?target=${target}&collection=${collection}&creator=${creator}`, {
      responseKind: "turbo-stream"
    })
  }
}
