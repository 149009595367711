import Swiper from "swiper/swiper-bundle.min"

window.Swiper = Swiper

require("swiper/swiper-bundle.min")

import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['collection']

    connect() {
        this.activateSwipers();
    }

    activateSwipers() {
        const id = this.element.id;

        var collectionSwiper = new Swiper("#" + id, {
            loop: false,
            spaceBetween: 1,
            breakpointsBase: 'container',
            setWrapperSize: true,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            }
        });
    }
}
