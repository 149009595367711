import Rails from "@rails/ujs";
import { Controller } from "stimulus"
import { get } from "@rails/request.js"
export default class extends Controller {
    static targets = ["dynamicField"]
    static values = {
        submitDelay: Number,
        siblingName: String,
    };

    connect() {
        this.setInitialValues();

        $(this.element)
            .on('input', `input:not(.excluded-auto-submit-form)`, () => {
                this.handleFormInputEvent()
            })
            .on('change', 'select', () => {
                this.handleFormInputEvent()
            })

        if (this.hasDynamicFieldTarget) {
            $(this.dynamicFieldTarget).on('DOMNodeRemoved', () => {
                if (this.dynamicFieldTarget.children.length > 0) {
                    this.handleFormInputEvent()
                }
            })
        }
    }

    disconnect() {
        $(this.element).off('input');
        $(this.element).off('cocoon:after-remove');

        this.clearSubmitTimer();
    }

    setInitialValues() {
        if (!this.hasDelayValue) {
            // Default submitDelay is 1000 millisecond.
            this.submitDelayValue = 1000;
        }
    }

    clearSubmitTimer() {
        clearTimeout(this.submitTimer)
    }

    handleFormInputEvent() {         
        this.clearSubmitTimer();
        this.submitTimer = setTimeout(() => {
            if (this.isValid) {
                this.fireSubmitEvent();
            }

        }, this.submitDelayValue);
    }

    async fireSubmitEvent() {
        let searchDataString = $(this.element).serialize();
        const response = await get(`${this.element.action}?${searchDataString}`, { responseKind: "turbo-stream" })
    }

    get isValid() {
        if (this.siblingController) {
            return this.siblingController.isValid;
        }
        // If siblingController is not present we assume that we don't have to check anything.
        return true
    }

    get siblingController() {
        return this.application.getControllerForElementAndIdentifier(this.element, this.siblingNameValue);
    }

    submitform () {                       
        this.handleFormInputEvent();
    }
}
