import { Controller } from "stimulus"
import { destroy, get, post, patch } from "@rails/request.js";
import Toastify from 'toastify-js';
// await get("/sync/wallet_modal", {responseKind: "turbo-stream"});

export default class extends Controller {
    static targets = ['analyticCheckbox', 'preferCheckbox']
    async connect() {
        const necessaryCookie = document.cookie.includes("NecessaryCookie=true")

        if (!necessaryCookie) {
            const cookieStyle = `
            <div>
            <b class="cookie-title dark:text-white">Cookie Use</h2>
            <p class="text-sm text-white">We use cookies to ensure a smooth browsing experience by continuing we assume you accept the use of cookies.</p>        
            <div class="flex items-center justify-between gap-x-4 shrink-0">        
                <button class="btn btn-transparent border border-white  text-white" data-action="click->cookie#acceptAll">Accept all</button>
                <button class="btn btn-transparent border border-white text-white" data-action="click->cookie#setting">Settings</button>
                <button class="btn btn-transparent text-white"data-action="click->cookie#closeToast">No thanks</button>
            </div>
                `
            const cookieForm = await Toastify({
                node: $.parseHTML(cookieStyle.trim())[0],
                duration: -1,
                gravity: "bottom",
                position: "left",
                escapeMarkup: false,
                offset: { x: 0, y: 0 },
                style: {
                    background: "#363640",
                  },
                className: "cookie-toasty"
            });

            const cookieSecStyle = `
            <div>  
            <div>
                <label>
                    <input type="checkbox" disabled="true" checked> Necessary Cookie
                </label>
                <p class="text-white">
                These cookies enable core function such as security, network management and accessibility. These cookies can't be switched off
                </p>
            </div>
            <div>
                <label>
                    <input type="checkbox" data-cookie-target="analyticCheckbox"> Analytics Cookie
                </label>
                <p class="text-white">
                These cookies help us better understand how visitor interact our website and help us discover errors
                </p>
            </div>
            <div>
                <label>
                    <input type="checkbox" data-cookie-target="preferCheckbox"> Preferences Cookie
                </label>
                <p class="text-white">
                These cookies allow the website to remember choices you've made to provide enhanced functionality and personalization
                </p>
            </div>
            <div class="flex items-center justify-between gap-x-4 shrink-0">                   
                <button class="btn btn-transparent border border-white text-white" data-action="click->cookie#saveFromSetting">Save</button>
                <button class="btn btn-transparent text-white"data-action="click->cookie#closeSettingToast">No thanks</button>
            </div>
                `
            const cookieSecForm = await Toastify({
                node: $.parseHTML(cookieSecStyle.trim())[0],
                duration: -1,
                gravity: "bottom",
                position: "left",
                escapeMarkup: false,
                offset: { x: 0, y: 0 },
                style: {
                    background: "#363640",
                  },
                className: "cookie-sec-toasty"
            });


            if ($(".cookie-toasty").length > 0) {

            } else {
                if ($(".cookie-sec-toasty").length > 0) {

                } else {
                    cookieForm.showToast()
                }
            }


            this.cookieSecForm = cookieSecForm
            this.cookieFormToast = cookieForm
        }


    }


    async acceptAll() {
        document.cookie = `NecessaryCookie=true; Max-Age=${400 * 24 * 60 * 60}; Domain=.${process.env.SHARE_COOKIE_DOMAIN};`
        document.cookie = `AnalyticsCookie=true; Max-Age=${400 * 24 * 60 * 60}; Domain=.${process.env.SHARE_COOKIE_DOMAIN};`
        document.cookie = `PreferencesCookie=true; Max-Age=${400 * 24 * 60 * 60}; Domain=.${process.env.SHARE_COOKIE_DOMAIN};`
        this.cookieFormToast.hideToast()
    }

    async setting() {
        this.cookieFormToast.hideToast()
        this.cookieSecForm.showToast()
    }


    async saveFromSetting() {
        document.cookie = `NecessaryCookie=true; Max-Age=${400 * 24 * 60 * 60}; Domain=.${process.env.SHARE_COOKIE_DOMAIN};`
        if (this.analyticCheckboxTarget.checked)
            document.cookie = `AnalyticsCookie=true; Max-Age=${400 * 24 * 60 * 60}; Domain=.${process.env.SHARE_COOKIE_DOMAIN};`
        if (this.preferCheckboxTarget.checked)
            document.cookie = `PreferencesCookie=true; Max-Age=${400 * 24 * 60 * 60}; Domain=.${process.env.SHARE_COOKIE_DOMAIN};`
        this.cookieSecForm.hideToast()
    }

    async closeSettingToast() {
        this.cookieSecForm.hideToast()
    }

    async closeToast() {
        this.cookieFormToast.hideToast()
    }
}
