import { Controller } from "stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
    static targets = ['nftClassSelect', 'approveBtn']
    
    connect() {
        console.log('connected admin--fantastic-moment')
    }

    async checkNftClass(event) {
        event.preventDefault()
        event.stopImmediatePropagation()
        
        let nftClassName = this.nftClassSelectTarget.selectedOptions[0].value
        if (nftClassName === '') {
            alert('Please select a NFT class before approving.')
        }
        else {
            await post(event.target.href, {
              responseKind: "turbo-stream"
            })
            Turbo.visit('')
        }
    }
}
