import {Controller} from "stimulus";
import {post} from "@rails/request.js";


export default class extends Controller {
    static targets = ["submit", "checkBox", "content"]

    connect() {
        const termsContent = this.contentTarget
        termsContent.addEventListener("scroll", (event) => {
            if (termsContent.scrollTop >= (termsContent.scrollHeight - termsContent.offsetHeight - 500)) {
                this.checkBoxTarget.disabled = false
                this.checkBoxTarget.parentElement.classList.remove('opacity-50')
            }
        })
    }

    onRowClickHandler(e) {
        if (this.checkBoxTarget.checked == true) {
            this.submitTarget.classList.remove('opacity-50')
            this.submitTarget.disabled = false
        } else {
            this.submitTarget.classList.add('opacity-50')
            this.submitTarget.disabled = true
        }

    }

    async onSubmit(e) {
            await post("/sync/submit_terms", {responseKind: 'turbo-stream'})
            Turbo.visit('')
    }
}
