import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
    static targets = ['parentSelect', 'nestedSelect']

    connect() {
        this.loadNestedOptions()
    }

    loadNestedOptions() {
        let parentId = this.parentSelectTarget.selectedOptions[0].value
        this.nestedSelectTargets.forEach((nestedSelect) => {
            let { optionsUrl, optionsSelected, optionsTargetId } = nestedSelect.dataset
            get(`${optionsUrl}${parentId}&selected=${optionsSelected}&target=${optionsTargetId}`, { 
                responseKind: "turbo-stream" 
            })
        })
    }
}
