import { Controller } from "stimulus";
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ['fmCheckbox']

  async removeFantasticMoments(event) {
    event.preventDefault()
    let selectedFms = this.fmCheckboxTargets.filter(checkbox => checkbox.checked == true).map(c => c.value)
    const response = await post(event.target.href, {
      body: JSON.stringify({ids: selectedFms}),
      responseKind: "turbo-stream"
    })
    Turbo.visit('')
  }

async moveToCollection(event) {
    event.preventDefault()
    let selectedFms = this.fmCheckboxTargets.filter(checkbox => checkbox.checked == true).map(c => c.value)
    await post(event.target.href, {
      body: JSON.stringify({ids: selectedFms}),
      responseKind: "turbo-stream"
    })
    Turbo.visit('')
  }
}
