import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["lang"];

    connect() {

    }


    show() {
        if (this.langTarget.classList[1] === 'hidden') {
            this.langTarget.classList.remove('hidden')
        } else {
            this.langTarget.classList.add('hidden')
        }
    }
}

