import { Dropdown } from "tailwindcss-stimulus-components"

export default class extends Dropdown {

    static targets = ["mainCheckBox"]

    connect() {
        super.connect();
    }

    toggle(event) {
        super.toggle();
        this.mainCheckBoxTarget.classList.add('input-checkbox');
    }

    hide(event) {
        super.hide(event);

        if(typeof event.target.dataset.id !== 'undefined') {
            // console.log(event.target);
            if(event.target.dataset.id !== this.mainCheckBoxTarget.dataset.id) {
                //Other box hide | Current box in process
                if(event.target.closest('[data-filter-dropdown-target="menu"]') == null) {
                    // In dropdown does not hide
                    this.mainCheckBoxTarget.classList.remove('input-checkbox');
                }

            }
        } else if(!event.target.classList.contains('input-custom')) {
            // Except checkbox does not hide
            this.mainCheckBoxTarget.classList.remove('input-checkbox');
        }
    }
}
