import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {

    connect() {
        let backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
            backdrop.remove();
        }
        // console.log(this.element);
        this.modal = new Modal(this.element);
        this.modal.show();
        // console.log(this.modal);
        this.element.addEventListener('hidden.bs.modal', (event) => {
            this.element.remove();
        });
        // console.log($(this.modal));
        // console.log($(this.element).find('crossmint-pay-button'));
        var tokenid = $(this.element).find('#buy').data('tokenid');
        var price = $(this.element).find('#buy').data('price');

        if($(this.element).find('crossmint-pay-button')) {
            const purchase_modal = $(this.element);
            purchase_modal.find('#amount').on('change', function() {
                purchase_modal.find('crossmint-pay-button').attr('mintConfig', '{"type":"erc-1155", "totalPrice": "' + (price*$(this).val()) + '", "tokenId_":"' + tokenid + '", "amount_":"' + $(this).val() + '"}');
            }).trigger("change");
        }
    }
}
