// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import HelloController from "../controllers/hello_controller"
import ImagePreviewController from "../controllers/image_upload_preview_controller"
import ConvertPriceController from "../controllers/convert_price_controller"
import FormPreviewController from "../controllers/form_preview_controller"
import AsTagListController from "../controllers/as_tag_list_controller"
import LicenseTypeController from "../controllers/license_type_controller"
import SubMenusController from "../controllers/sub_menus_controller"
import NftTypeController from "../controllers/nft_type_controller"
import NftCreationFieldsController from "../controllers/nft_creation_fields_controller"
import FilterDropdownController from "../controllers/filter_dropdown_controller"
import FilterTagsController from "../controllers/filter_tags_controller"
import CookieControllor from "../controllers/cookie_controller"
import TurnstileController from "../controllers/turnstile_controller"
import ReadMore from 'stimulus-read-more'
import TermsModal from "./terms_modal_controller"
import TermsCheckbox from './terms_checkbox_controller'
import Accordion from "./accordion_controller"

window.Stimulus = Application.start()
const context = require.context("controllers", true, /_controller\.(js|ts)$/)
Stimulus.load(definitionsFromContext(context))

Stimulus.register("hello", HelloController)
Stimulus.register("image-upload-preview", ImagePreviewController)
Stimulus.register("convert-price", ConvertPriceController)
Stimulus.register('form-preview',FormPreviewController)
Stimulus.register('as-tag-list',AsTagListController)
Stimulus.register('license-type',LicenseTypeController)
Stimulus.register('sub-menus',SubMenusController)
Stimulus.register('nft-type',NftTypeController)
Stimulus.register('nft-creation-fields', NftCreationFieldsController)
Stimulus.register("filter-dropdown", FilterDropdownController)
Stimulus.register("filter-tags", FilterTagsController)
Stimulus.register("cookie",CookieControllor)
Stimulus.register('turnstile', TurnstileController)
Stimulus.register('read-more', ReadMore)
Stimulus.register('terms-modal', TermsModal)
Stimulus.register('terms-checkbox', TermsCheckbox)
Stimulus.register('accordion', Accordion)
