import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab"]

  connect() {
    this.tabTargets[0].classList.add('uk-active')
  }

  setActive(event) {
    this.tabTargets.forEach(function(tab) { 
      tab.classList.remove('uk-active') 
    })
    event.target.closest('li').classList.add('uk-active')
  }
}
