import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["box"]

  connect() {
  }

  toggle(event) {
    if (event.target.checked) {
      this.boxTarget.classList.remove('d-none');
      return;
    }
    this.boxTarget.classList.add('d-none');
  }

}
