import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
      this.checkUrlForAccordion()

      window.addEventListener("hashchange", (event) => {
        const newHash = event.newURL ? event.newURL.split('#')[1] : window.location.hash.slice(1)
        this.expandAccordion(newHash)
      })
    }

    checkUrlForAccordion() {
      const hash = window.location.hash.slice(1)
      if (hash) {
        this.expandAccordion(hash)
      }
    }

    expandAccordion(elemId) {
      const accordionElem = document.getElementById(elemId)

      if (accordionElem) {
        const bsCollapse = new bootstrap.Collapse(accordionElem, {
          show: true
        })
      }
    }

    changeTab(event) {
      const bsTarget = event.target.dataset.bsTarget
      const hash = window.location.hash
      if (hash !== bsTarget) {
        window.history.replaceState(null, null, bsTarget);
      }
    }
}
