import {Controller} from "stimulus"
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller {
    static values = {
        redirectPath: String,
        openPaths: Array,
    };

    connect() {
        this.openPathsValue.forEach((path) => {
            window.open(path)
        })
        if (this.hasRedirectPathValue) {
            Turbo.visit(this.redirectPathValue, {action: "replace"});
        } else {
            window.location.reload();
        }
    }
}
