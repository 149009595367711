import { Controller } from "stimulus";
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["element", "countrySelect", "leagueSelect", "subLeagueSelect", "clubSelect"];
  //Country > League > Sub-League > Club
  //Do another 4 onChange functions
  onChangeSport(e){ //change Country options
    let sport = e.target.selectedOptions[0].value
    console.log(sport);
    let target = this.countrySelectTarget.id
    console.log(target);
    get(`/athletes/countries?target=${target}&sport=${sport}`, {
      responseKind: "turbo-stream"
    })
    athlete_league_id.length = 0
    athlete_sub_league.length = 0
    athlete_club.length = 0
  }

  onChangeCountry(e){ //change League options
    let sport = athlete_sport_id.value
    console.log(sport);
    let country = e.target.selectedOptions[0].value
    console.log(country);
    console.log("Inside selected: "+e.target.selectedOptions[0].textContent);
    let target = this.leagueSelectTarget.id
    console.log(target);
    // console.log(target);
    // console.log(this.leagueSelectTarget.id);
    get(`/athletes/leagues?target=${target}&sport=${sport}&country=${country}`, {
      responseKind: "turbo-stream"
    })
    athlete_sub_league.length = 0
    athlete_club.length = 0
  }

  onChangeLeague(e){ //change Sub League options
    let league = e.target.selectedOptions[0].textContent
    let target = this.subLeagueSelectTarget.id
    get(`/athletes/sub_leagues?target=${target}&league=${league}`, {
      responseKind: "turbo-stream"
    })
    athlete_club.length = 0
  }

  onChangeSubLeague(e){ //change Sub League options
    let sub_league = e.target.selectedOptions[0].textContent
    let target = this.clubSelectTarget.id
    get(`/athletes/clubs?target=${target}&sub_league=${sub_league}`, {
      responseKind: "turbo-stream"
    })
  }

  change(e){
    console.log('Hello');
  }

  connect() {
    console.log(this.elementTargets);
    console.log("hello form example");
  }

  handleClickToShow(event) {
    this.elementTargets.forEach((element) => {
      
      if (element.dataset.parentValue === event.target.value) {
        element.style.visibility = "visible";
      } else {
        element.style.visibility = "hidden";
      }
    });
  }
}
