import {Controller} from "stimulus"

// import select2 from "select2"
export default class extends Controller {
    static targets = ['nftName', 'groupSubmit', 'button'];

    connect() {
        // this.submitValidateNftName();
    }

    submitValidateNftName(event) {
        let nft_name_boxes = $('[data-nft-creation-fields*="nftName"]');
        let nft_alert_messages_box = $(".nft-alert-message.alert-danger");
        let nft_nested_boxes = $(".nested-fields.nft-form-nested");
        let upload_form = $('.upload-nft-form');

        if(nft_alert_messages_box.length > 0) {
            nft_alert_messages_box.remove();
        }

        if(nft_nested_boxes.length > 0) {
            nft_alert_messages_box.remove();
        }

        if(nft_nested_boxes.length === 0) {
            upload_form.prepend('<div id="add_nfts" class="nft-alert-message alert-danger mb-3">Please upload NFiniTs</div>');
            $('#add_nfts')[0].scrollIntoView({ behavior: 'smooth' });

            event.preventDefault();
            return false;
        }

        nft_name_boxes.each(function () {
            if(this.value === "") {
                $(this).parent().find(".context-messages").append('<div class="nft-alert-message alert-danger mb-2">NFiniT Name must be filled</div>')
            }
        });
    }
}
