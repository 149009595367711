import {Controller} from "stimulus";
import {get} from "@rails/request.js";
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ["stateSelect", "test", "typeSelect"]

    connect() {
    }


    change(event) {
        let type = event.target.selectedOptions[0].value
        const {numb} = event.currentTarget.dataset
        let target = this.stateSelectTargets[numb].id

        Rails.ajax({
            url: `/nested_select_options/types?name=${type}`,
            type: 'GET',
            data: {some_parameter: target},
            dataType: "json",
            success: function (data) {
                let check = '#' + target
                let check_selected = "select#" + target + " option"

                // console.log(check,check_selected)

                $(check_selected).remove();

                // $('#athlete_creator_showcases_attributes_0_name_showcase_id').append(`<option value="${real[0].id}">
                //                         ${real[0].name}
                //                    </option>`);


                $.each(data, function (i, j) {
                    $(check).append(`<option value="${j.id}">
                                       ${j.name}
                                  </option>`);
                })


            }
        })

        // get(`/nested_select_options/types?name=${type}`,{
        //     responseKind: "turbo-stream"
        // })
    }


    add() {
        for (let i = 0; i < 4; i++) {
            let a
            if ((a = document.getElementById("showcase_" + (i + 1).toString())).style.display == 'none') {
                a.style.display = 'block'
                break;
            }
        }

    }


    hide(event) {
        event.preventDefault()
        const {idx} = event.currentTarget.dataset
        let target = this.typeSelectTargets[idx].id
        document.getElementById(target).selectedIndex = 0

        this.showHide(idx)

    }


    showHide(idx) {
        let id = idx
        const x = document.getElementById("showcase_" + (parseInt(id) + 1));
        x.style.display === 'block' ? x.style.display = "none" : x.style.display = "block"
    }
}
