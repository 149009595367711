import {Controller} from "stimulus"
// import {useCurrentUser} from "../hooks/use-current-user.hook"

export default class extends Controller {

    connect() {
        // const [, setCurrentUser] = useCurrentUser();
        // window.ethereum.subscribe(setCurrentUser)

    }
}
