import {Controller} from "stimulus";
import {post} from "@rails/request.js";

export default class extends Controller {
    static targets = ["submit", "input"];

    async onSubmit(e) {
        console.log(this.inputTarget.value)
        const options = {
            body: JSON.stringify({
                password: this.inputTarget.value
            }),
            responseKind: "turbo-stream"
        };
        await post("/sync/submit_password_initialized", options);
        Turbo.visit("");
    }
}
