import {Controller} from "stimulus";
import {get} from "@rails/request.js";


export default class extends Controller {
    static targets = ["submit"]

    async connect() {
        await get("/terms_modal", {responseKind: "turbo-stream"});
    }
}
