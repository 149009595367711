import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    //Countdown
    this.countdowns = [];
    var countdowns = document.querySelectorAll("#countdown");
    countdowns.forEach((el, idx) => {
      let timestamp = el.dataset.timestamp;
      if (timestamp > 0) {
        this.update_counter(timestamp, idx);
      }
    });
  }

  disconnect() {
    // Clear setInterval of sale counter
    this.countdowns.forEach((cd) => {
      clearInterval(cd);
    });
  }

  update_counter(ts, idx) {
    const index = idx;
    var timestamp = ts * 1000 + 60000 - Date.now();

    if (timestamp > 0) {
      timestamp /= 1000; // from ms to seconds

      function component(x, v) {
        return Math.floor(x / v);
      }

      this.countdowns[idx] = setInterval(function() {
        timestamp--;

        var days = component(timestamp, 24 * 60 * 60),
          hours = component(timestamp, 60 * 60) % 24,
          minutes = component(timestamp, 60) % 60,
          seconds = component(timestamp, 1) % 60;

        document.querySelectorAll(".days_remaining")[index].textContent =
          days < 0 ? "00" : days < 10 ? "0" + days.toString() : days;

        document.querySelectorAll(".hours_remaining")[index].textContent =
          hours < 0 ? "00" : hours < 10 ? "0" + hours.toString() : hours;

        document.querySelectorAll(".minutes_remaining")[index].textContent =
          minutes < 0
            ? "00"
            : minutes < 10
            ? "0" + minutes.toString()
            : minutes;

        /* $div.html(days + " days, " + hours + ":" + minutes + ":" + seconds) */
        // console.log(days + " days, " + hours + ":" + minutes + ":" + seconds);
      }, 1000);
    } else {
      document.querySelectorAll(".days_remaining")[index].textContent = "00";
      document.querySelectorAll(".hours_remaining")[index].textContent = "00";
      document.querySelectorAll(".minutes_remaining")[index].textContent = "00";
    }
  }
}
