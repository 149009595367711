// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import "controllers"
import "@hotwired/turbo-rails"
require("@popperjs/core")
require("@nathanvda/cocoon")
require("@crossmint/client-sdk-vanilla-ui");
import "jquery"
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
import "@fortawesome/fontawesome-free/js/all";
import "jquery-ui-dist/jquery-ui"
import "jquery-ui-touch-punch/jquery.ui.touch-punch.js"
import "imask"
import 'select2'
import "toastify-js"

require('../packs/swiper');
require('../packs/characters_count');
require('../packs/nft_creation');
require('../packs/turnstile');
require("../stylesheets/application.scss");


