import {Controller} from "stimulus"
import select2 from "select2"

export default class extends Controller {
    static targets = ['inputTaglist', 'collection','selectTwo', 'addBtn', 'duplicateTags']

    connect() {

        let defaultOption =  {
            tags: true,
            tokenSeparators: [','],
            theme: "bootstrap-5",
            maximumSelectionLength: 5,
            closeOnSelect: true,
            minimumInputLength: 1,
            templateSelection: formState,
            templateResult: resultState,
        };

        $(this.selectTwoTarget).select2(defaultOption);

        $(this.selectTwoTarget).on('select2:select', function () {
            if($(this).closest('.tags_selector').length > 0) {
                var duplicate_tags = $(this).closest('.tags_selector').find('.duplicate-tags');
                duplicate_tags.empty();

                $(this).parent().find('.select2-selection ul').children().each(function () {
                    if(duplicate_tags.find('div[title="' + $(this).attr('title') +'"]').length < 1) {

                        var capitalize_letter = $(this).attr('title').charAt(0).toUpperCase() + $(this).attr('title').slice(1).toLowerCase();

                        duplicate_tags.append('<div class="duplicate-tag-list" title="' + $(this).attr('title') + '">' + capitalize_letter + '</div>');
                    }
                });
            }
        });

        $(this.selectTwoTarget).on('select2:unselect', function () {
            if($(this).closest('.tags_selector').length > 0) {
                var duplicate_tags = $(this).closest('.tags_selector').find('.duplicate-tags');
                var remining_tags = $(this).parent().find('.select2-selection__rendered');

                duplicate_tags.children().each(function () {

                    if(remining_tags.find('li[title="' + $(this).attr('title') + '"]').length < 1) {
                        $(this).remove();
                    }
                });
            }
        });
    }

    clickShow(e) {
        if (this.collectionTarget.classList.value.search("d-none") !== -1) {
            this.collectionTarget.classList.remove("d-none")
        } else {
            this.collectionTarget.classList.add("d-none")
        }
    }
}

function formState(state) {
    return setElementToSelect2(state, 'nft-tags-selection')
}

function resultState(state) {
    return setElementToSelect2(state, 'nft-tags-result')
}

function setElementToSelect2(state, css_class) {
    var capitalize_letter = '';

    if (!state.id) {
        return state.text;
    }

    if(css_class === 'nft-tags-result') {
        capitalize_letter = state.text.toLowerCase();
    } else if(css_class === 'nft-tags-selection') {
        capitalize_letter = state.text.charAt(0).toUpperCase() + state.text.slice(1).toLowerCase();
    }

    var $state = $(
        '<span class="' + css_class +'"> ' + capitalize_letter + '</span>'
    );

    return $state;
}
