import {Controller} from "stimulus"
import NP from 'number-precision'

export default class extends Controller {
    static targets = ["subCollapse", "changeColor", "collaspeButton", "linear", "closeButton", "headerContainer", "logoColor", "profile", "menu","wallet"];

    async connect() {
        // console.log('Start',document.body.clientWidth,this.walletTarget.dataset.wallet)
        if(this.hasWalletTarget) {
            if (document.body.clientWidth <= 600.00) {
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML = wallet.substr(0, 5) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length - 4, this.walletTarget.dataset.wallet.length)
            } else if (document.body.clientWidth <= 640.00) {
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML = wallet.substr(0, 9) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length - 8, this.walletTarget.dataset.wallet.length)
            } else if (document.body.clientWidth <= 680.00) {
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML = wallet.substr(0, 13) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length - 12, this.walletTarget.dataset.wallet.length)

            } else if (document.body.clientWidth <= 720.00) {
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML = wallet.substr(0, 17) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length - 16, this.walletTarget.dataset.wallet.length)

            } else if (document.body.clientWidth <= 760.00) {
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML = wallet.substr(0, 21) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length - 20, this.walletTarget.dataset.wallet.length)

            } else if (document.body.clientWidth >= 800.00) {
                this.walletTarget.innerHTML = this.walletTarget.dataset.wallet
            }
        }


        window.addEventListener('resize', async () => {

            let width = document.body.clientWidth
            // console.log(width)
            if (NP.strip(width) > 1150.00) {
                // this.headerContainerTarget.classList.add('d-flex')
                // console.log('Remove')
                this.headerContainerTarget.classList.remove('container-custom')
                this.headerContainerTarget.classList.remove('nav-shadow')
                this.changeColorTarget.classList.remove('navbar-mb-bg')
            } else if (this.profileTarget.classList.contains('show') == true) {
                // console.log('ADD 0')
                this.headerContainerTarget.classList.add('container-custom')
                this.headerContainerTarget.classList.add('nav-shadow')
                this.changeColorTarget.classList.add('navbar-mb-bg')
            } else if (this.menuTarget.classList.contains('show') == true) {
                // console.log('ADD 1')
                this.headerContainerTarget.classList.add('container-custom')
                this.headerContainerTarget.classList.add('nav-shadow')
                this.changeColorTarget.classList.add('navbar-mb-bg')
            } else {
                // this.headerContainerTarget.classList.remove('d-flex')
            }

        if(this.hasWalletTarget){
            if(NP.strip(width)  <= 600.00){
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML =  wallet.substr(0,6) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length-4,this.walletTarget.dataset.wallet.length)
            }else if(NP.strip(width)  <= 640.00){
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML =  wallet.substr(0,9) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length-8,this.walletTarget.dataset.wallet.length)
            }else if(NP.strip(width)  <= 680.00){
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML =  wallet.substr(0,13) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length-12,this.walletTarget.dataset.wallet.length)

            }else if(NP.strip(width)  <= 720.00){
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML =  wallet.substr(0,17) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length-16,this.walletTarget.dataset.wallet.length)

            }else if(NP.strip(width)  <= 760.00){
                const wallet = this.walletTarget.dataset.wallet
                const te = this.walletTarget.dataset.wallet.length / 2
                this.walletTarget.innerHTML =  wallet.substr(0,21) + '...' + wallet.substr(this.walletTarget.dataset.wallet.length-20,this.walletTarget.dataset.wallet.length)

            }else if(NP.strip(width) >= 800.00) {
                this.walletTarget.innerHTML = this.walletTarget.dataset.wallet
            }
        }

        });

        $(this.menuTarget).on('show.bs.collapse', async () => {
            this.headerContainerTarget.classList.add('container-custom')
            this.headerContainerTarget.classList.add('nav-shadow')
            this.collaspeButtonTargets[1].classList.add('profile-moblie')
            this.collaspeButtonTargets[0].classList.add('hidden-important')
            this.closeButtonTargets[0].classList.remove('hidden-important')
            this.logoColorTarget.classList.add('bg-nav-black')
            this.linearTarget.classList.add('show-linear')
            this.changeColorTarget.classList.add('navbar-mb-bg')
            this.changeColorTarget.classList.remove('bg-transparent')
        })

        $(this.profileTarget).on('show.bs.collapse', async () => {
            if (this.menuTarget.classList.contains('show') == true) {
                localStorage.setItem('collapse', 'true')
                $(this.menuTarget).collapse('hide')
                localStorage.setItem('reload', 'true')
                $(this.profileTarget).collapse('show')
            } else {
                if (localStorage.getItem('reload') == 'true') {

                } else {
                    this.headerContainerTarget.classList.add('container-custom')
                    this.headerContainerTarget.classList.add('nav-shadow')
                    this.collaspeButtonTargets[1].classList.add('profile-moblie')
                    this.collaspeButtonTargets[0].classList.add('hidden-important')
                    this.closeButtonTargets[1].classList.remove('hidden-important')
                    this.logoColorTarget.classList.add('bg-nav-black')
                    this.linearTarget.classList.add('show-linear')
                    this.changeColorTarget.classList.add('navbar-mb-bg')
                    this.changeColorTarget.classList.remove('bg-transparent')
                }
            }

        })


        $(this.profileTarget).on('hidden.bs.collapse', async () => {
            this.headerContainerTarget.classList.remove('container-custom')
            this.headerContainerTarget.classList.remove('nav-shadow')
            this.collaspeButtonTargets[1].classList.remove('profile-moblie')
            this.collaspeButtonTargets[0].classList.remove('hidden-important')
            this.closeButtonTargets[1].classList.add('hidden-important')
            this.linearTarget.classList.remove('show-linear')
            this.logoColorTarget.classList.remove('bg-nav-black')
            this.changeColorTarget.classList.remove('navbar-mb-bg')
            this.changeColorTarget.classList.add('bg-transparent')
        })

        $(this.menuTarget).on('hidden.bs.collapse', async () => {

            if (localStorage.getItem('collapse') == 'true') {
                localStorage.setItem('collapse', 'false')
                localStorage.setItem('reload', 'true')
            } else {
                this.headerContainerTarget.classList.remove('container-custom')
                this.headerContainerTarget.classList.remove('nav-shadow')
                this.collaspeButtonTargets[1].classList.remove('profile-moblie')
                this.collaspeButtonTargets[0].classList.remove('hidden-important')
                this.closeButtonTargets[0].classList.add('hidden-important')
                this.linearTarget.classList.remove('show-linear')
                this.logoColorTarget.classList.remove('bg-nav-black')
                this.changeColorTarget.classList.remove('navbar-mb-bg')
                this.changeColorTarget.classList.add('bg-transparent')
            }
        })

        $(this.profileTarget).on('shown.bs.collapse', async () => {
            if (localStorage.getItem('reload') == 'true') {
                localStorage.setItem('reload', 'false')
                this.headerContainerTarget.classList.add('container-custom')
                this.headerContainerTarget.classList.add('nav-shadow')
                this.collaspeButtonTargets[1].classList.add('profile-moblie')
                this.collaspeButtonTargets[0].classList.add('hidden-important')
                this.closeButtonTargets[1].classList.remove('hidden-important')
                this.logoColorTarget.classList.add('bg-nav-black')
                this.linearTarget.classList.add('show-linear')
                this.changeColorTarget.classList.add('navbar-mb-bg')
                this.changeColorTarget.classList.remove('bg-transparent')
            } else {

            }
        })

        $(this.menuTarget).on('hide.bs.collapse', async () => {
            if (localStorage.getItem('collapse') == 'true') {
                this.headerContainerTarget.classList.remove('container-custom')
                this.headerContainerTarget.classList.remove('nav-shadow')
                this.collaspeButtonTargets[1].classList.remove('profile-moblie')
                this.collaspeButtonTargets[0].classList.remove('hidden-important')
                this.closeButtonTargets[0].classList.add('hidden-important')
                this.linearTarget.classList.remove('show-linear')
                this.logoColorTarget.classList.remove('bg-nav-black')
                this.changeColorTarget.classList.remove('navbar-mb-bg')
                this.changeColorTarget.classList.add('bg-transparent')
            }
        })


    }

}
