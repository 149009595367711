import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  connect() {
    console.log(this.elementTargets);
    console.log("hello form example");
  }

  handleClickToShow(event) {
    this.elementTargets.forEach((element) => {
      
      if (element.dataset.parentValue === event.target.value) {
        element.style.visibility = "visible";
      } else {
        element.style.visibility = "hidden";
      }
    });
  }

  hello(){
    console.log("hello!");
  }
}
