$(document).on("turbo:load turbo:frame-load turbo:frame-render", function () {

    $('.characters_counts').each(function () {
        set_characters_counts($(this));
    });
});

function set_characters_counts(current_element) {
    let el = current_element;
    var characterCount = el.find('.count-characters').val().length,
        current = el.find('#current');
    current.text(characterCount);

    current_element.find('textarea[count-characters], .count-characters').keyup(function() {
        var characterCount = $(this).val().length,
            current = el.find('#current'),
            maximum = el.find('#maximum'),
            theCount = el.find('.count-content');

        current.text(characterCount);
    });
}