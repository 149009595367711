import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["tab"];

    connect() {
        if(typeof this.tabTargets[0] !== 'undefined') {
            this.tabTargets[0].classList.add('active');
        }
    }

    setActiveTab(e) {
        this.tabTargets.forEach(function(tab) {
            tab.classList.remove('active')
        })

        e.target.classList.add("active")


        // this.tabMod2Targets.forEach(function(tab) {
        //     tab.classList.remove('sort-link-active-mod2')
        //   });
      
        //   if(e.target.closest('.sort-link-mod') !== null) {
        //       e.target.closest('.sort-link-mod').classList.add('sort-link-active-mod2');
        //   } else {
        //       this.tabMod2Targets[0].classList.add('sort-link-active-mod2');
        //   }
    }
}

