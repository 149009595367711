import {Controller} from "stimulus"
import {ethers} from "ethers"
import {
    checkPasswordInit,
    checkAccount,
    checkUser,
    useInit,
    checkCurrentUser,
    checkTerm
} from "../hooks/user-init.hook";
import { clearLocalStorageAuthen, CHAIN_ID } from "../global/constant"
import abiMembership from "../../../abi/NFiniTiMembership.json";
// import {Modal} from "tailwindcss-stimulus-components";
import {destroy, get, post, patch} from "@rails/request.js";
import Web3Modal from "web3modal";
import abiMinting from "../../../abi/NFiniTi1155.json";
// import {fantasticoAddress} from "../global/constant";
// import {marketplaceAddress} from "../global/constant";
// import Fantastico from '../../../abi/Fantastico1155.json'
// import Marketplace from '../../../abi/Marketplace1155.json'
// import {store} from "../store";
// import isEmpty from "lodash/isEmpty";
export default class extends Controller {
    static targets = ["address", "wallet", "connect", "discon", "side", "disuser", "test", "matic", "network", "error", "output",
        "profileWalletAddress", "profileWalletBalance", "complete", "fail", "catching", "pendings", "message", "continueFirst", "continueSec",
        "marketplace", "owned", "showMarketplace", "greet", "ownedNft", "creatorCollection", "clipboard", "networkStatus", "profileDiscon", "profileConnect",
        "option","input","confirm", "picture", "collection", "currentCollection", "body", "switchNetwork"];

    //Setup Torus
    async initTorus() {

        const torus = new window.Torus({buttonPosition: "top-right"});

        await torus.init({
            loginConfig: {
                'linkedin':{
                    showOnModal: false,
                },
                'discord':{
                    showOnModal: false,
                },
                'reddit': {
                    showOnModal: false,
                },
                'twitch': {
                    showOnModal: false,
                },
                'torus-auth0-twitter': {
                    showOnModal: false,
                },
                'torus-auth0-line': {
                    showOnModal: false,
                },
                'torus-auth0-apple': {
                    showOnModal: false,
                },
                'torus-auth0-kakao': {
                    showOnModal: false,
                },
                'torus-auth0-email-passwordless': {
                    showOnModal: false,
                },
                'torus-auth0-github': {
                    showOnModal: false,
                },
                'torus-auth0-wechat': {
                    showOnModal: false,
                },
            },
            useLocalStorage: true,
            enableLogging: true,
            network: {
                host: NETWORK,
            },
            showTorusButton: false,
            enabledVerifiers: {},
            integrity: true,
            whiteLabel: {
                theme: {
                    isDark: true,
                    colors: {
                        torusBrand1: "#000000"
                    }
                },
                logoDark: "https://tkey.surge.sh/images/Device.svg", // Dark logo for light background
                logoLight: "https://tkey.surge.sh/images/Device.svg", // Light logo for dark background
            }
        });
        this.sdk = torus

    }

    //Setup Wallet Connect Metamask Coinbase
    async initWeb3() {
        const providerOptions = {
            walletconnect: {
                package: window.WalletConnectProvider.default,
                options: {
                    infuraId: process.env.INFURA_KEY
                }, qrcodeModalOptions: {
                    mobileLinks: [
                        "rainbow",
                        "metamask",
                        "argent",
                        "trust",
                        "imtoken",
                        "pillar",
                        "eidoo",
                    ],
                },

            }
        };

        const web3Modal = new Web3Modal({
            network: NETWORK,
            cacheProvider: true,
            providerOptions
        });

        this.web3Modal = web3Modal;
    }

    //Event On listent Exmaple When your Logout wallet and change address
    async web3Sub(connect) {
        connect.on("accountsChanged", async (accounts) => {

            if (localStorage.getItem("connect") == "true") {
                if (localStorage.getItem("ProviderConnect") == "walletconnect") {
                    if (localStorage.getItem("chainChange") == "true") {
                        localStorage.removeItem("chainChange")
                    } else {
                        const check = await checkAccount(accounts[0])

                        if (check == 'true') {

                            Turbo.visit('')
                        } else {
                            await this.web3Modal.clearCachedProvider();
                            await clearLocalStorageAuthen()
                            this.connectTarget.classList.remove("hidden")
                            this.disconTarget.classList.add("hidden")
                            this.profileConnectTarget.classList.remove("hidden")
                            this.profileDisconTarget.classList.add("hidden")
                            await get("/sync/error", {responseKind: "turbo-stream"});
                        }
                    }
                } else {
                    if (accounts[0] == undefined) {
                        await this.web3Modal.clearCachedProvider();
                        await clearLocalStorageAuthen()
                        Turbo.visit("/");
                    } else {
                        const check = await checkAccount(accounts[0])

                        if (check == 'true') {
                            Turbo.visit('')
                        } else {
                            await this.web3Modal.clearCachedProvider();
                            await clearLocalStorageAuthen()
                            // Turbo.visit("/");
                            this.connectTarget.classList.remove("hidden")
                            this.disconTarget.classList.add("hidden")
                            this.profileConnectTarget.classList.remove("hidden")
                            this.profileDisconTarget.classList.add("hidden")
                            await get("/sync/error", {responseKind: "turbo-stream"});
                        }
                    }

                }
            }


        });

        // Subscribe to chainId change
        connect.on("chainChanged", async (chainId) => {
            if (localStorage.getItem("connect") == "true") {
                console.log('Switch network');
                if (chainId !== ethers.utils.hexValue(CHAIN_ID)) {
                    this.switchNetworkTarget.classList.remove("hidden")
                    this.disconTarget.classList.add("hidden")
                    await this.requestNetwork(this.provider)
                } else {
                    this.switchNetworkTarget.classList.add("hidden")
                    this.disconTarget.classList.remove("hidden")
                }
                this.checkMembership(this.address, this.signer).then(() => {
                    // Update session after get membership from contract
                    this.updateMembershipOnline();
                });

                localStorage.setItem("chainChange", "true")
            }
        });

        // Subscribe to provider disconnection
        connect.on("disconnect", async (error, msg) => {
            console.log(error, msg);
            if (localStorage.getItem("connect") == "true") {
                if (localStorage.getItem("ProviderConnect") == "walletconnect") {
                    await this.web3Modal.clearCachedProvider();
                    await clearLocalStorageAuthen()
                    Turbo.visit("/");
                }
            }
        });

    }

    async connect() {
        console.log(NETWORK);
        if (localStorage.getItem("connect") == "true") {
            // Condition Check provider torus or web3modal
            const init = localStorage.getItem("isProvider") == "Torus" ? await this.initTorus() : await this.initWeb3();
            const isLogin = localStorage.getItem("isProvider") == "Torus" ? this.sdk.isLoggedIn : this.web3Modal.cachedProvider;
            const getWalletProvider = localStorage.getItem("isProvider") == "Torus" ? this.sdk.provider : await this.web3Modal.connectTo(localStorage.getItem("ProviderConnect"));
            this.getWalletProvider = getWalletProvider

            if (isLogin) {
                if (localStorage.getItem("isProvider") == 'Web3Modal') {
                    await this.web3Sub(getWalletProvider)
                }
                //Setup value
                const provider = new ethers.providers.Web3Provider(getWalletProvider, 'any');
                const { chainId } = await provider.getNetwork()

                // Manage button switch and disconnect
                this.connectTarget.classList.add("hidden")
                if (chainId !== CHAIN_ID) {
                    this.switchNetworkTarget.classList.remove("hidden")
                } else {
                    this.disconTarget.classList.remove("hidden")
                }

                await this.requestNetwork(provider)

                const signer = await provider.getSigner()
                const address = await signer.getAddress();
                const balance = await signer.getBalance();
                this.signer = signer;
                this.provider = provider;
                this.address = address;


                this.profileConnectTarget.classList.add("hidden")
                this.profileDisconTarget.classList.remove("hidden")
            } else {
                //Check If wallet disconected
                if (localStorage.getItem("isProvider") == "Torus") {
                    this.connectTarget.classList.remove("hidden")
                    this.disconTarget.classList.add("hidden")
                    this.profileConnectTarget.classList.remove("hidden")
                    this.profileDisconTarget.classList.add("hidden")
                    await this.sdk.login({verifier: localStorage.getItem("login")});
                    Turbo.visit("");
                }
            }
            this.checkMembership(this.address, this.signer);
        }
    }

    async checkMembership(wallet_address, signer) {
        const contract = new ethers.Contract(
            process.env.MEMBERSHIP_CONTRACT,
            abiMembership,
            signer
        );

        try {
            const mintable = await contract.getHighestMembershipLevel(wallet_address);
            localStorage.setItem("membershipLevel", parseInt(mintable._hex, 16));
            console.log('Membership Activated', mintable._hex);
        } catch (error) {
            console.log('No membership');
            localStorage.removeItem("membershipLevel");
        }
    }

    async updateMembershipOnline() {
        const membership_level = localStorage.getItem("membershipLevel");

        const pictures = this.pictureTargets.map((el) => {
            return el.dataset.id
        });

        const collections = this.collectionTargets.map((el) => {
            return el.dataset.id
        });

        let current_collection,current_picture;

        if($("[data-authenticate-target*='currentCollection']").length > 0) {
            current_collection = this.currentCollectionTarget.dataset.id;
            current_picture = this.currentCollectionTarget.dataset.pictureId;
        }

        if(membership_level !== null) {
            await patch("/user/update_session", {responseKind: "turbo-stream",
                body: JSON.stringify({
                    pictures: pictures, collections: collections, current_collection: current_collection, current_picture: current_picture, membership_level: membership_level
                })
            });

        }
    }

    //Open Wallet Modal
    async loginWallet(e) {
        e.preventDefault();
        await get("/sync/wallet_modal", {responseKind: "turbo-stream"});
    }

    //Switch Case select Wallet
    async selectWallet(e) {
        e.preventDefault();
        switch (this.inputTarget.value) {
            case "metamask":
                await this.loginMetamask();
                break;
            case "torus":
                await this.loginTorus();
                break;
            case "walletconnect":
                await this.loginWalletConnect();
                break;
            default:
        }
    }

    async selectRadioOption() {
        this.optionTargets.forEach((el, i) => {
            el.classList.toggle("active", event.target == el)
        })
        this.inputTarget.value = event.target.value
        this.confirmTarget.disabled = false
    }

    //Torus
    async loginTorus() {
        await this.initTorus();
        await this.sdk.login();

        const provider = new ethers.providers.Web3Provider(this.sdk.provider);
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        const option = {
            body: JSON.stringify({
                wallet_address: address,
                provider: "torus"
            }),
            responseKind: "turbo-stream"
        };

        const checkWallet = await useInit(option);
        const checkCurrent = checkWallet;

        await this.checkMembership(address, signer);

        if (checkCurrent == "true") {
            await this.updateMembershipOnline();
            localStorage.setItem("connect", "true");
            localStorage.setItem("isInit", "true");
            localStorage.setItem("isProvider", "Torus");

            this.connectTarget.classList.add("hidden")
            this.profileConnectTarget.classList.add("hidden")
            window.location.reload();
            this.disconTarget.classList.remove("hidden")
            this.profileDisconTarget.classList.remove("hidden")
        } else {
            await this.sdk.logout();
            await this.sdk.clearInit();
            await this.sdk.cleanUp();
            await clearLocalStorageAuthen()
            await get("/sync/error", {responseKind: "turbo-stream"});
        }
    }

    //Metamask
    async loginMetamask() {
        await this.initWeb3();
        const connect = await this.web3Modal.connectTo('injected');

        const provider = new ethers.providers.Web3Provider(connect);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        const { chainId } = await provider.getNetwork()

        const option = {
            body: JSON.stringify({
                wallet_address: address,
                provider: "metamask"
            }),
            responseKind: "turbo-stream"
        };
        //
        const checkWallet = await useInit(option);
        const checkCurrent = checkWallet;

        await this.checkMembership(address, signer);

        if (checkCurrent == "true") {
            await this.updateMembershipOnline();
            localStorage.setItem("connect", "true");
            localStorage.setItem("isInit", "true");
            localStorage.setItem("isProvider", "Web3Modal");
            localStorage.setItem("ProviderConnect", "injected");
            this.connectTarget.classList.add("hidden")
            this.profileConnectTarget.classList.add("hidden")
            // Turbo.visit("");
            window.location.reload();
            if (chainId !== CHAIN_ID) {
                this.switchNetworkTarget.classList.remove("hidden")
            } else {
                this.disconTarget.classList.remove("hidden")
            }
            this.profileDisconTarget.classList.remove("hidden")
        } else {
            await this.web3Modal.clearCachedProvider();
            await clearLocalStorageAuthen()
            await get("/sync/error", {responseKind: "turbo-stream"});
        }
    }

    //WalletConnect
    async loginWalletConnect() {
        await this.initWeb3();
        const connect = await this.web3Modal.connectTo('walletconnect');
        //
        const provider = new ethers.providers.Web3Provider(connect);
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        const option = {
            body: JSON.stringify({
                wallet_address: address,
                provider: "walletconnect"
            }),
            responseKind: "turbo-stream"
        };
        //
        const checkWallet = await useInit(option);
        const checkCurrent = checkWallet;

        await this.checkMembership(address, signer);

        if (checkCurrent == "true") {
            await this.updateMembershipOnline();
            localStorage.setItem("connect", "true");
            localStorage.setItem("isInit", "true");
            localStorage.setItem("isProvider", "Web3Modal");
            localStorage.setItem("ProviderConnect", "walletconnect");
            this.connectTarget.classList.add("hidden")
            this.profileConnectTarget.classList.add("hidden")
            window.location.reload();
            this.disconTarget.classList.remove("hidden")
            this.profileDisconTarget.classList.remove("hidden")
        } else {
            await clearLocalStorageAuthen()
            await get("/sync/error", {responseKind: "turbo-stream"});
        }
    }

    //Click Disconnect
    async logoutWallet(event) {
        localStorage.setItem("membershipLevel", 0);
        await this.updateMembershipOnline();

        if (localStorage.getItem("isProvider") == "Torus") {
            event.preventDefault();
            await this.sdk.logout();
            await this.sdk.clearInit();
            await this.sdk.cleanUp();
            // await get(`/user/logout`)
            localStorage.removeItem("login");
            localStorage.setItem("isInit", "false");
            localStorage.setItem("connect", "false");
            localStorage.setItem("isProvider", "none");
            localStorage.setItem("ProviderConnect", "none");
            this.connectTarget.classList.remove("hidden")
            this.disconTarget.classList.add("hidden")

            this.profileConnectTarget.classList.remove("hidden")
            this.profileDisconTarget.classList.add("hidden")
        }

        if (localStorage.getItem("isProvider") == "Web3Modal") {
            event.preventDefault();
            await this.web3Modal.clearCachedProvider();
            localStorage.removeItem("login");
            localStorage.setItem("isInit", "false");
            localStorage.setItem("connect", "false");
            localStorage.setItem("isProvider", "none");
            localStorage.setItem("ProviderConnect", "none");
            this.connectTarget.classList.remove("hidden")
            this.disconTarget.classList.add("hidden")
            this.profileConnectTarget.classList.remove("hidden")
            this.profileDisconTarget.classList.add("hidden")
        }
    }

    //Click Logout
    async logout(event) {
        event.preventDefault();
        localStorage.setItem("membershipLevel", 0);
        localStorage.removeItem("connect", 0);
        await this.updateMembershipOnline();
        if (localStorage.getItem("connect") == "true") {
            if (localStorage.getItem("isProvider") == 'Torus') {
                await this.sdk.logout();
                await this.sdk.clearInit();
                await this.sdk.cleanUp();
            } else {
                await this.web3Modal.clearCachedProvider();
            }
            await get(`/user/logout`);
            await clearLocalStorageAuthen()
            Turbo.visit("/");
        } else {
            await get(`/user/logout`);
            await clearLocalStorageAuthen()
            Turbo.visit("/");
        }

    }

    async changeNetwork(e) {
        await this.requestNetwork(this.provider)
    }


    async copy(event) {
        event.preventDefault();
        navigator.clipboard.writeText(this.address.toLowerCase())
    }


    async closeAlert() {
        this.errorTarget.classList.add('hidden')
    }


    async setupNetwork(e) {
        e.preventDefault()
        try {
            await this.getWalletProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ eth_chainId: ethers.utils.hexValue(CHAIN_ID) }],
            });
            Turbo.visit('')
        } catch (e) {
            console.log(e)
            this.contentTarget.textContent = e.message
        }
    }

    async requestNetwork(provider) {
        const { chainId } = await provider.getNetwork()
        if (chainId !== CHAIN_ID) {
            console.log(`Request switch to ${NETWORK} network.`)
            try {
                await provider.send('wallet_switchEthereumChain', [{ chainId: ethers.utils.hexValue(CHAIN_ID) }]);
            } catch (error) {
                console.error(error)
            }
        }

    }
}


