import {Controller} from "stimulus"
import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {

    connect() {
        super.connect();
        this.open();

        // this.bindLoadingModal();
        // console.log("modal Controller")
        // console.log(this.containerTarget.classList)

    }

    disconnect() {

    }

    open(){

        // Lock the scroll and save current scroll position
        this.lockScroll();
        // console.log("open")

        // this.toggleClass
        // Unhide the modal
        this.containerTarget.classList.remove(this.toggleClass);
        // console.log(this.containerTarget);/**/
        // Insert the background
        if (!this.data.get("disable-backdrop")) {
            document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
            this.background = document.querySelector(`#${this.backgroundId}`);


        }



    }

     close(){
        this.unlockScroll();
        this.containerTarget.classList.add(this.toggleClass);
        if (this.background) { this.background.remove() }
            // alert("Close Modal")
            //  window.location.reload();/**/
    }


}
